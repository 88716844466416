import React, { forwardRef, useCallback, useEffect, useState } from "react";
import styles from "./StoreReturnVerifyInwardRow.module.scss";
import { LogisticsService } from "@sk/services";
import { debounce, sortBy } from "lodash";
import { Amount, DisplayUnit, KeyVal } from "@sk/uis";

const locations = LogisticsService.getLocations();

const whId = "WMF28";

const reasons = [
  "Defective Product",
  "Wrong Item Shipped",
  "Item Not As Described",
  "Sizing Issue",
  "Changed Mind",
  "Arrived Too Late",
  "Damaged in Transit",
  "Quality Not As Expected",
  "Incomplete Order",
  "Duplicate Order",
  "Allergic Reaction",
  "Product Recall",
  "Received Extra Item",
  "Packaging Issue",
  "Expired Product",
];

const StoreReturnVerifyInwardRow = forwardRef(
  ({ data, isSplit, callback, index }, ref) => {
    const [areas, setAreas] = useState([]);
    const [racks, setRacks] = useState([]);
    const [bins, setBins] = useState([]);

    const [editableMrp, setEditableMrp] = useState(data.mrp);

    useEffect(() => {
      setEditableMrp(data.mrp);
    }, [data.mrp]);

    const updateLocationData = (type, value) => {
      callback({
        action: "updateLocation",
        data: {
          dealId: data.dealId,
          splitId: isSplit ? data._id : null,
          locationType: type,
          locationValue: value,
        },
      });
    };

    const handleVerifiedQtyChange = (e) => {
      let verifiedQty = e.target.value;
      const requestedQty = Number(data.requestedQuantity);

      // If the input is empty or not a number, allow it
      if (verifiedQty === "" || isNaN(verifiedQty)) {
        verifiedQty = "";
      } else {
        // Convert to number for comparison
        verifiedQty = Number(verifiedQty);

        // Ensure the value is not less than 0
        if (verifiedQty < 0) {
          verifiedQty = 0;
        }

        // If the verified quantity exceeds the requested quantity, cap it
        if (verifiedQty > requestedQty) {
          verifiedQty = requestedQty;
        }
      }

      callback({
        action: "updateVerifiedQty",
        data: {
          dealId: data.dealId,
          splitId: isSplit ? data._id : null,
          verifiedQty: verifiedQty,
        },
      });
    };

    const handleSplit = () => {
      callback({
        action: "split",
        data: data.dealId,
      });
    };

    const handleRemoveSplit = () => {
      callback({
        action: "removeSplit",
        data: {
          dealId: data.dealId,
          splitId: data._id,
        },
      });
    };

    const fetchAreas = async (location) => {
      try {
        const areasData = await LogisticsService.getWhAreas(whId, {
          filter: { location },
        });
        const sortedAreas = Array.isArray(areasData.resp)
          ? sortBy(areasData.resp, "name")
          : [];
        setAreas(sortedAreas);
      } catch (error) {
        setAreas([]);
      }
    };

    const fetchRacks = async (areaId) => {
      try {
        const racksData = await LogisticsService.getWhRacks(whId, areaId);
        const sortedRacks = Array.isArray(racksData.resp)
          ? sortBy(racksData.resp, "name")
          : [];
        setRacks(sortedRacks);
      } catch (error) {
        setRacks([]);
      }
    };

    const handleLocationChange = async (e) => {
      const location = e.target.value;
      updateLocationData("location", location);
      // Reset area, rack, and bin when location changes
      updateLocationData("area", "");
      updateLocationData("rack", "");
      updateLocationData("bin", "");
      setAreas([]);
      setRacks([]);
      setBins([]);
      if (location) {
        await fetchAreas(location);
      }
    };

    const handleAreaChange = async (e) => {
      const areaId = e.target.value;
      updateLocationData("area", areaId);
      if (areaId) {
        await fetchRacks(areaId);
      }
    };

    const handleRackChange = (e) => {
      const rackId = e.target.value;
      updateLocationData("rack", rackId);
      updateLocationData("bin", "");
      const selectedRackData = racks.find((rack) => rack._id === rackId);
      const sortedBins = selectedRackData?.bins
        ? sortBy(selectedRackData.bins, "name")
        : [];
      setBins(sortedBins);
    };

    const handleBinChange = (e) => {
      const binId = e.target.value;
      updateLocationData("bin", binId);
    };

    const updateMrp = useCallback(
      (value) => {
        const newMrp = parseFloat(value);
        const currentMrp = parseFloat(data.mrp);

        // Calculate the allowed range (50% lower to 50% higher)
        const minAllowed = currentMrp * 0.5;
        const maxAllowed = currentMrp * 1.5;

        let finalMrp;

        if (isNaN(newMrp)) {
          // If the input is not a valid number, revert to the current value
          finalMrp = currentMrp;
        } else if (newMrp < minAllowed) {
          // If below minimum, set to minimum allowed
          finalMrp = minAllowed;
        } else if (newMrp > maxAllowed) {
          // If above maximum, set to maximum allowed
          finalMrp = maxAllowed;
        } else {
          // If within range, use the new value
          finalMrp = newMrp;
        }

        setEditableMrp(finalMrp);

        callback({
          action: "updateMrp",
          data: {
            dealId: data.dealId,
            splitId: data._id,
            mrp: finalMrp,
          },
        });
      },
      [data.mrp, data.dealId, data._id, callback]
    );

    const debouncedUpdateMrp = useCallback(debounce(updateMrp, 800), [
      updateMrp,
    ]);

    const handleMrpChange = (e) => {
      const value = e.target.value;
      setEditableMrp(value);
      debouncedUpdateMrp(value);
    };

    const handleReasonChange = (e) => {
      const reason = e.target.value;
      callback({
        action: "updateReason",
        data: {
          dealId: data.dealId,
          splitId: isSplit ? data._id : null,
          reason: reason,
        },
      });
    };

    return (
      <tr ref={ref} className={`${isSplit ? "bg-light" : ""}`}>
        {!isSplit && <td>{index + 1}</td>}
        {!isSplit && (
          <td>
            <div className="mb-2">{data.dealName}</div>
            <KeyVal label="Deal ID" fwSize="sm">
              : {data.dealId}
            </KeyVal>
            <KeyVal label="Barcode" noBottomSpace fwSize="sm">
              : {data.barcode || "N/A"}
            </KeyVal>
          </td>
        )}
        {isSplit && <td colSpan="2"></td>}
        <td colSpan={isSplit ? 2 : 1}>
          {isSplit ? (
            <div className="d-flex flex-column">
              <label htmlFor="mrp-input" className="mb-1 fs-val-sm">
                MRP:
              </label>
              <div
                className="input-group input-group-sm"
                style={{ width: "120px" }}
              >
                <span className="input-group-text">₹</span>
                <input
                  id="mrp-input"
                  type="number"
                  className="form-control form-control-sm"
                  placeholder="Enter MRP"
                  value={editableMrp}
                  onChange={handleMrpChange}
                />
              </div>
            </div>
          ) : (
            <Amount value={data.mrp} />
          )}
        </td>
        {!isSplit && (
          <td>
            <KeyVal label="Requested Qty" labelCol="col-5" contentCol="col-7">
              :{" "}
              <DisplayUnit
                value={data.requestedQuantity}
                isLoose={data.sellingLooseQty}
              />
            </KeyVal>
            <KeyVal
              label="Received Qty"
              noBottomSpace
              labelCol="col-5"
              contentCol="col-7"
            >
              :{" "}
              <DisplayUnit
                value={data.receivedQuantity || 0}
                isLoose={data.sellingLooseQty}
              />
            </KeyVal>
          </td>
        )}
        <td className="me-4">
          <div className="d-flex flex-column">
            {/* <label htmlFor="verified-qty-input" className="mb-1 fs-val-sm">
              Verified Qty:
            </label> */}
            <div
              className="input-group input-group-sm"
              style={{ width: "120px" }}
            >
              <input
                id="verified-qty-input"
                type="number"
                className="form-control form-control-sm"
                value={data._verifiedQty || ""}
                onChange={handleVerifiedQtyChange}
                placeholder="Enter Verified Qty"
                autoFocus={true}
              />
            </div>
            {data.qtyErrMsg && (
              <div className="text-danger fs-val-sm mt-1">{data.qtyErrMsg}</div>
            )}

            <label htmlFor="reason-select" className="mt-3 mb-1 fs-val-sm">
              Reason:
            </label>
            <div className={styles["custom-select-container"]}>
              <select
                id="reason-select"
                className={styles["custom-select"]}
                value={data.selectedReason || ""}
                onChange={handleReasonChange}
              >
                <option value="">Choose</option>
                {sortBy(reasons).map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </td>
        <td>
          <div className="row g-2">
            <div className="col-6">
              <label htmlFor="location" className="fs-val-xs">
                Location
              </label>
              <div className={styles["custom-select-container"]}>
                <select
                  id="location"
                  className={styles["custom-select"]}
                  value={data.selectedLocation || ""}
                  onChange={handleLocationChange}
                >
                  <option value="">Choose</option>
                  {sortBy(locations, "name").map((loc) => (
                    <option key={loc.name} value={loc.name}>
                      {loc.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="area" className="fs-val-xs">
                Area
              </label>
              <div className={styles["custom-select-container"]}>
                <select
                  id="area"
                  className={styles["custom-select"]}
                  value={data.selectedArea || ""}
                  onChange={handleAreaChange}
                >
                  <option value="">Choose</option>
                  {areas.map((area) => (
                    <option key={area._id} value={area._id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6 mb-2">
              <label htmlFor="rack" className="fs-val-xs">
                Rack
              </label>
              <div className={styles["custom-select-container"]}>
                <select
                  id="rack"
                  className={styles["custom-select"]}
                  value={data.selectedRack || ""}
                  onChange={handleRackChange}
                >
                  <option value="">Choose</option>
                  {racks.map((r) => (
                    <option key={r._id} value={r._id}>
                      {r.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="bin" className="fs-val-xs">
                Bin
              </label>
              <div className={styles["custom-select-container"]}>
                <select
                  id="bin"
                  className={styles["custom-select"]}
                  value={data.selectedBin || ""}
                  onChange={handleBinChange}
                >
                  <option value="">Choose</option>
                  {bins.map((b) => (
                    <option key={b._id} value={b._id}>
                      {b.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </td>

        <td className="text-center">
          {isSplit ? (
            <button
              className="btn btn-sm btn-danger"
              onClick={handleRemoveSplit}
            >
              Remove Split
            </button>
          ) : (
            <button className="btn btn-sm btn-primary" onClick={handleSplit}>
              Split
            </button>
          )}
        </td>
      </tr>
    );
  }
);

StoreReturnVerifyInwardRow.displayName = "StoreReturnVerifyInwardRow";

export default StoreReturnVerifyInwardRow;

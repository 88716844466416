import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  InfoPopover,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useNavigate } from "react-router-dom";

const headers = [
  { label: "Sl No", width: "4%" },
  { label: "ID", width: "8%" },
  { label: "Store Details", width: "15%" },
  { label: "Return To", width: "8%" },
  { label: "Warehouse", width: "20%" },
  { label: "Total Items", width: "7%" },
  { label: "Total Stock", width: "7%" },
  { label: "Status", width: "14%" },
  { label: "Created On", width: "8%" },
  { label: "Action", width: "9%", isCentered: true },
];

const rbac = {
  verify: ["VerifyStoreReturns"],
  inward: ["InwardStoreReturns"],
  view: ["ViewStoreReturns"],
  assignDriver: ["AssignDriverToStoreReturns"],
};

function StoreReturnTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) {
  const router = useNavigate();

  const verify = (id) => {
    NavService.to(router, "/logistics/store-returns/verify", {
      id,
    });
  };

  const view = (id) => {
    NavService.openInNewTab("/logistics/store-returns/view", {
      id,
    });
  };

  const inward = (id) => {
    NavService.openInNewTab("/logistics/store-returns/inward", {
      id,
    });
  };

  const assignDriver = (id, index) => {
    callback({
      action: "assignDriver",
      data: { id, index },
    });
  };

  return (
    <>
      <div className="mb-1">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <table className="table table-sm bg-white table-hover">
        <TableHeader data={headers} noBg />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader cols={headers.length} rows={10} height={40} />
          ) : null}

          {data.map((x, index) => (
            <tr key={x._id} className="fs-val-md">
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x._id}</td>
              <td>
                <div className="text-primary mb-1">{x.franchise.name}</div>
                <div className="fs-val-sm">
                  <span className="text-muted">FID:</span> {x.franchise.id}
                </div>
                {/* <div className="fs-val-sm">
                  Kengeri, Bangalore, Karnataka - 560040
                </div> */}
              </td>
              <td>
                <div className="text-uppercase">
                  {x.returnTo == "SK" ? "StoreKing" : "Vendor"}
                </div>
              </td>
              <td>
                <div className="mb-1">{x.whInfo?.name}</div>
                <div className="fs-val-sm text-muted text-truncate-2">
                  {x.whInfo?.address?.door_no}, {x.whInfo?.address?.street},
                  {x.whInfo?.district}, {x.whInfo?.state} -{" "}
                  {x.whInfo?.address?.pincode}
                </div>
              </td>
              <td>{x.totalItems} items</td>
              <td>{x.displayStockUnitStr}</td>
              <td>
                <HighlightText isInline={true} template={2} type={x.statusType}>
                  {x.displayStatus}
                </HighlightText>
                {x.statusDescription && (
                  <InfoPopover content={x.statusDescription} placement="top" />
                )}
              </td>
              <td>
                <DateFormatter date={x.createdAt} format="dd MMM yyyy" />
                <div className="text-muted fs-val-sm">
                  <DateFormatter date={x.createdAt} format="hh:mm a" />
                </div>
              </td>
              <td className="text-end">
                <div className="d-flex flex-column gap-1">
                  {x._canVerify ? (
                    <Rbac roles={rbac.verify}>
                      <button
                        className="btn btn-outline-danger text-uppercase btn-sm w-100 fs-val-sm action-btn"
                        onClick={() => verify(x._id)}
                      >
                        Verify
                      </button>
                    </Rbac>
                  ) : null}

                  {x._canAssignDriver ? (
                    <Rbac roles={rbac.assignDriver}>
                      <button
                        className="btn btn-outline-success text-uppercase btn-sm w-100 fs-val-sm action-btn"
                        onClick={() => assignDriver(x._id, index)}
                      >
                        Assign Driver
                      </button>
                    </Rbac>
                  ) : null}

                  {x._canInward ? (
                    <Rbac roles={rbac.inward}>
                      <button
                        className="btn btn-outline-warning text-uppercase btn-sm w-100 action-btn fs-val-sm"
                        onClick={() => inward(x._id)}
                      >
                        Inward
                      </button>
                    </Rbac>
                  ) : null}

                  <Rbac roles={rbac.view}>
                    <button
                      className="btn btn-outline-primary text-uppercase btn-sm w-100 fs-val-sm action-btn"
                      onClick={() => view(x._id)}
                    >
                      View
                    </button>
                  </Rbac>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
}

export default StoreReturnTable;

import { StoreCard } from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";
import { LogisticsService, NavService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  NoDataFound,
  PageInfo,
  PageLoader,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set, startOfMonth } from "date-fns";
import { merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StoreShipmentFilter from "./StoreShipmentFilter";

const StoreShipments = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const queryParams = useFetchUrlQueryString(searchParams);

  const [headerData, setHeaderData] = useState([]);

  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState({
    loading: false,
    count: 0,
  });

  const filterDataRef = useRef({
    date: [startOfMonth(new Date()), new Date()],
    status: "shipmentArrival",
  });
  const paginationRef = useRef({
    ...defaultPaginationConfig,
  });
  const sortRef = useRef({
    key: "createdAt",
    value: "desc",
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    setRecordsCount({ loading: true, count: 0 });
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    paginationRef.current.totalRecords = r.count;
    setRecordsCount({ loading: false, count: r.count });
  }, [loadList]);

  const loadList = useCallback(async () => {
    setLoading(true);
    setShipments([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      ),
      filterDataRef.current.fid,
      filterDataRef.current.franSubType === "SF"
    );
    setShipments(r.data);
    setLoading(false);
  }, []);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    setPageLoading(true);
    filterDataRef.current = {
      ...filterDataRef.current,
      fid: queryParams.fid,
      status: "shipmentArrival",
    };

    setHeaderData(getHeaderData(filterDataRef.current.status));
  }, [queryParams.fid]);

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };
    setHeaderData(getHeaderData(filterDataRef.current.status));
    console.log(filterDataRef.current);
    applyFilter();
  };

  const onSortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  const onStoreCardCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      sellerId: data.franchiseDetails.sellerId,
    };
    setPageLoading(false);
    init();
  };

  const viewOrder = (shipment) => {
    NavService.openInNewTab(`/oms/orders/details`, {
      id: shipment.orderId,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
        <PageInfo breadcrumbs={breadcrumbs} title={title} noMargin />
      </div>

      {queryParams?.fid ? (
        <StoreCard
          fid={queryParams?.fid}
          callback={onStoreCardCb}
          navigate={navigate}
        />
      ) : null}

      {pageLoading ? (
        <PageLoader />
      ) : (
        <>
          <AppCard>
            <StoreShipmentFilter callback={onFilterCb} />
          </AppCard>

          <AppCard>
            <PaginationSummary
              loadingTotalRecords={recordsCount.loading}
              paginationConfig={paginationRef.current}
              className="mb-3"
            />

            <div
              style={tableContainerStyle}
              className="custom-scrollbar thin-scrollbar"
            >
              <table className="table table-sm table-striped">
                <TableHeader
                  data={headerData}
                  noBg
                  sortCb={onSortCb}
                  sort={sortRef.current}
                  isSticky={true}
                />
                <tbody className="fs-val-md">
                  {loading ? (
                    <TableSkeletonLoader cols={headerData.length} rows={5} />
                  ) : null}

                  {!loading && !shipments.length ? (
                    <tr>
                      <td colSpan={headerData.length}>
                        <NoDataFound>No shipments found</NoDataFound>
                      </td>
                    </tr>
                  ) : null}

                  {shipments.map((shipment, index) => (
                    <tr key={shipment._id}>
                      <td>{index + 1}</td>
                      <td>{shipment._boxNo}</td>
                      <td>{shipment.actualInvoiceNo}</td>
                      <td>
                        <button
                          className="btn btn-link text-dark fs-val-md p-0 text-start"
                          onClick={() => viewOrder(shipment)}
                        >
                          {shipment.orderId}
                        </button>
                      </td>
                      <td>
                        <DateFormatter date={shipment.orderDate} />
                      </td>
                      <td>
                        <span
                          className={`ord-type-bdg ${shipment._orderTypeCss}`}
                        >
                          {shipment._orderType}
                        </span>
                      </td>
                      <td>
                        <DateFormatter date={shipment.shippedOn} />
                      </td>
                      <td>
                        {filterDataRef.current.status === "delivered" ? (
                          <DateFormatter date={shipment.ReceivedOn} />
                        ) : (
                          <DateFormatter date={shipment.arrivingFromSk} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <PaginationBlock
                loadingTotalRecords={recordsCount.loading}
                paginationCb={onPaginationCb}
                size="sm"
                paginationConfig={paginationRef.current}
              />
            </div>
          </AppCard>
        </>
      )}
    </>
  );
};

// Add this style object for the table container
const tableContainerStyle = {
  maxHeight: "400px", // Set the maximum height for the table
  overflow: "auto", // Enable scrolling if content exceeds the height
};

const getHeaderData = (status) => {
  const hasReceivedStatus = status === "delivered";

  return [
    { key: "_slNo", label: "Sl No", width: "3%" },
    { key: "boxId", label: "Box ID", width: "10%" },
    { key: "invoice", label: "Invoice", width: "10%" },
    { key: "order", label: "Order", width: "10%" },
    { key: "orderedOn", label: "Ordered On", width: "10%" }, // New column
    { key: "orderType", label: "Order Type", width: "10%" },
    { key: "shippedOn", label: "Shipped From SK", width: "10%" },
    {
      key: hasReceivedStatus ? "receivedOn" : "arrivingOn",
      label: hasReceivedStatus ? "Received On" : "Arriving On",
      width: "10%",
    },
  ];
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params, fid, isSfSeller) => {
  const r = await LogisticsService.getLogistics(params, fid, isSfSeller);
  console.log(r);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await LogisticsService.getLogisticsCount(p);
  return { count: r.resp };
};

const prepareFilterParams = (filter, pagination) => {
  let status = filter.status || "";

  let searchFilter = [];

  let networkFilter = [];

  let orderTypeFilter = [];

  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
    sort: "shippedOn",
  };

  if (filter.search?.trim()) {
    const patt = {
      $regex: filter.search.trim(),
      $options: "gi",
    };
    searchFilter = [
      {
        invoiceNo: patt,
      },
      {
        actualInvoiceNo: patt,
      },
      {
        motherBox: patt,
      },
      {
        orderId: patt,
      },
    ];
  }

  // for the network filter
  // if (this.selectedRetailer && this.selectedRetailer._id) {
  //   p.filter.destination = this.selectedRetailer._id;
  // }

  // for the received packages

  if (filter.status === "delivered") {
    p.sort = "-ReceivedOn";
    p.filter.status = "Delivered";
    if (filter.franSubType === "SF") {
      p.filter.currNode = filter.fid;
    } else {
      p.filter.destination = filter.fid;
    }
  }

  if (filter.orderType == "myOrder") {
    p.filter.destination = filter.fid;
    orderTypeFilter = [
      {
        isRFLinkedOrder: {
          $exists: false,
        },
      },
      {
        isRFLinkedOrder: false,
      },
    ];
  }

  if (filter.orderType == "reqOrder") {
    p.filter.isRFLinkedOrder = true;
  }

  if (filter.orderType == "networkOrder") {
    p.filter.source = filter.sellerId;
    p.filter.destination = { $ne: filter.sellerId };
  }

  const t = LogisticsService.getLogisticsStatusFilter(
    status,
    filter.fid,
    filter.sellerId,
    filter.franSubType === "SF"
  );

  p = merge({}, p, t);

  // for date filter
  if (filter.date[0] && filter.date[1]) {
    let dtKey = "";
    let dt = {
      $gte: set(filter.date[0], { hours: 0, minutes: 0 }),
      $lte: set(filter.date[1], { hours: 23, minutes: 59 }),
    };

    if (filter.status === "delivered") {
      p.filter.$and = [
        {
          $or: [
            {
              sfReceivedOn: dt,
            },
            {
              arrivedAtRf: dt,
            },
          ],
        },
      ];
    }

    if (dtKey && filter.status === "delivered") {
      p.filter[dtKey] = dt;
    }
  }

  if (
    searchFilter.length > 0 ||
    networkFilter.length > 0 ||
    orderTypeFilter.length > 0
  ) {
    if (!p.filter.$and) {
      p.filter.$and = [];
    }
    if (searchFilter.length > 0) {
      p.filter.$and.push({ $or: searchFilter });
    }
    if (networkFilter.length > 0) {
      p.filter.$and.push({ $or: networkFilter });
    }
    if (orderTypeFilter.length > 0) {
      p.filter.$and.push({ $or: orderTypeFilter });
    }
  }

  let finalPayload = merge({}, p);

  // if there is any $and cond, then move all data inside the filter to filter.$and
  if (p.filter.$and) {
    const andCond = [...finalPayload.filter.$and];
    Object.entries(p.filter).forEach(([key, value]) => {
      if (key !== "$and") {
        andCond.push({ [key]: value });
      }
    });
    finalPayload.filter = { $and: andCond };
  }

  return finalPayload;
};

const title = "Store Shipments";
const breadcrumbs = [{ name: "Home", link: "/" }, { name: title }];

export default StoreShipments;

import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { Navigate, RouterProvider, createHashRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import StoreReturnsList from "./pages/store-returns/list/StoreReturnsList";
import StoreReturnVerify from "./pages/store-returns/verify/StoreReturnVerify";
import StoreReturnView from "./pages/store-returns/view/StoreReturnView";
import StoreReturnInward from "./pages/store-returns/inward/StoreReturnInward";
import StoreReturnVerifyInward from "./pages/store-returns/verify-inward/StoreReturnVerifyInward";
import StoreShipments from "./pages/store/shipments/StoreShipments";

const rbac = {
  storeReturns: {
    list: ["ListStoreReturns"],
    verify: ["VerifyStoreReturns"],
    view: ["ViewStoreReturns"],
    inward: ["InwardStoreReturns"],
    verifyInward: ["VerifyInwardStoreReturns"],
  },
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "logistics",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Navigate to="/logistics/store-returns"></Navigate>,
          },
          {
            path: "store-returns",
            element: (
              <ProtectedRoute roles={rbac.storeReturns.list}>
                <StoreReturnsList />
              </ProtectedRoute>
            ),
          },
          {
            path: "store-returns/verify",
            element: (
              <ProtectedRoute roles={rbac.storeReturns.verify}>
                <StoreReturnVerify />
              </ProtectedRoute>
            ),
          },
          {
            path: "store-returns/view",
            element: (
              <ProtectedRoute roles={rbac.storeReturns.view}>
                <StoreReturnView />
              </ProtectedRoute>
            ),
          },
          {
            path: "store-returns/inward",
            element: (
              <ProtectedRoute roles={rbac.storeReturns.inward}>
                <StoreReturnInward />
              </ProtectedRoute>
            ),
          },
          {
            path: "store-returns/inward-verify",
            element: (
              <ProtectedRoute roles={rbac.storeReturns.verifyInward}>
                <StoreReturnVerifyInward />
              </ProtectedRoute>
            ),
          },
          {
            path: "store-shipments",
            element: <StoreShipments />,
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);

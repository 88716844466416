import { useFetchUrlQueryString } from "@sk/hooks";
import {
  AuthService,
  FranchiseService,
  LogisticsService,
  NavService,
} from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  InfoBlk,
  NoDataFound,
  PageInfo,
  PageLoader,
  Toaster,
} from "@sk/uis";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StoreReturnBasicInfo from "../components/StoreReturnBasicInfo";
import StoreReturnDealsTable from "../components/StoreReturnDealsTable";
import RejectReasonModal from "./modals/RejectReasonModal";
import produce from "immer";
import { orderBy } from "lodash";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Store Returns",
    link: "/logistics/store-returns",
  },
  {
    name: "Verify",
  },
];

const warehouses = orderBy(
  AuthService.getLoggedInEmpWh() || [],
  ["name"],
  ["asc"]
);

function StoreReturnVerify() {
  const router = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [busyloader, setBusyloader] = useState({ show: false });

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({
    basicInfo: {},
    deals: [],
  });

  const [rejectModal, setRejectModal] = useState({
    show: false,
    deal: {},
    type: "",
  });

  const [wh, setWh] = useState("");

  const selectedWhInfo = useRef({});

  const init = async (id) => {
    setLoading(true);
    const r = await LogisticsService.getStoreReturns({ filter: { _id: id } });
    const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};
    let b = {};
    [
      "_id",
      "name",
      "franchiseId",
      "createdAt",
      "displayStockUnitStr",
      "_totalDeals",
      "status",
      "statusType",
      "franchiseInfo",
      "displayStatus",
      "modifiedBy",
      "modifiedAt",
      "requestCreatedBy",
      "whInfo",
    ].forEach((e) => {
      b[e] = d[e];
    });

    setDetails({
      _id: d._id,
      basicInfo: b,
      deals: (d.deals || []).map((e) => ({ ...e, status: "Pending" })),
      _canVerify: d._canVerify,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (query.id) {
      init(query.id);
    }
  }, [query]);

  const approve = async () => {
    if (!wh) {
      Toaster.error("Please choose Warehouse");
      return;
    }

    const a = await Alert.confirm({
      title: "Approve Request",
      text: "Do you want to proceed?",
      okText: "Yes",
      cancelText: "No",
    });

    if (a.isConfirmed) {
      const w = warehouses.find((e) => e._id == wh);

      let p = {
        whInfo: {
          name: w.name,
          id: wh,
          _id: wh,
          address: selectedWhInfo.current.address,
          city: selectedWhInfo.current.city,
          district: selectedWhInfo.current.district,
          town: selectedWhInfo.current.town,
          state: selectedWhInfo.current.state,
        },
        deals: details.deals.map((e) => {
          let t = {
            ...e,
            status: e._rejectRemarks ? "Rejected" : "Approved",
            rejectRemarks: e._rejectRemarks,
          };
          delete t._rejectRemarks;
          return t;
        }),
      };

      const r = await LogisticsService.approveStoreReturn(
        details.basicInfo._id,
        p
      );

      if (r.statusCode == 200) {
        const approvedDeals = details.deals.filter((e) => !e._rejectRemarks);

        if (approvedDeals.length > 0) {
          const generateInvoice = await LogisticsService.generateReturnInvoice(
            details.basicInfo._id,
            approvedDeals.map((e) => ({
              stockMasterId: e.stockMasterId,
              productId: e.productId,
              selectedInvoiceId: e.invoiceInfo?.id,
            }))
          );
          if (generateInvoice.statusCode != 200) {
            Toaster.error(
              generateInvoice.resp?.message || "Failed to generate invoice"
            );
            setBusyloader({ show: false });
            return;
          }
        }

        setBusyloader({ show: false });

        Toaster.success("Approved successfully");
        NavService.replace(router, "/logistics/store-returns");
      } else {
        Toaster.error(r.resp?.message || "Failed to approve, try again");
      }
    }
  };

  const reject = () => {
    setRejectModal({ show: true, deal: {}, type: "overall" });
  };

  const rejectModalCb = async (e) => {
    setRejectModal({ show: false });

    if (rejectModal.type == "deal" && e.action == "submit") {
      setDetails(
        produce((draft) => {
          // deal index
          const i = details.deals.findIndex((v) => v?.dealId == e.deal?._id);
          if (i != -1) {
            draft.deals[i]._rejectRemarks = e.remarks.trim();
            draft.deals[i].status = "Rejected";
          }
        })
      );
    }

    if (e.action == "submit" && rejectModal.type != "deal") {
      setBusyloader({ show: true });
      const r = await LogisticsService.rejectStoreReturn(
        details.basicInfo._id,
        e.remarks.trim()
      );
      setBusyloader({ show: false });
      if (r.statusCode == 200) {
        Toaster.success("Rejected successfully");
        NavService.replace(router, "/logistics/store-returns");
      } else {
        Toaster.error(r.resp?.message || "Failed to reject, try again");
      }
    }
  };

  const rejectDeal = (index) => {
    setRejectModal({
      show: true,
      type: "deal",
      deal: { name: "Aarshidvad Atta 1 kg" },
    });
  };

  const tblCb = (e) => {
    if (e.action == "rejectDeal") {
      setRejectModal({
        show: true,
        deal: { name: e.deal.dealName, _id: e.deal.dealId },
        type: "deal",
      });
    }

    if (e.action == "cancelReject") {
      setDetails(
        produce((draft) => {
          draft.deals[e.index].status = "Pending";
          draft.deals[e.index]._rejectRemarks = "";
        })
      );
    }
  };

  const onWhChange = async (e) => {
    setWh(e.target.value);
    setBusyloader({ show: true });
    const r = await FranchiseService.getFranchises({
      filter: { _id: e.target.value },
    });
    setBusyloader({ show: false });
    if (r.statusCode == 200) {
      selectedWhInfo.current = r.resp[0];
    }
  };

  return (
    <>
      <PageInfo
        title={"Verify Store Returns - " + query?.id}
        breadcrumbs={breadcrumbs}
        navigate={router}
      />

      {loading ? <PageLoader /> : null}

      {!loading && !details?._id ? (
        <NoDataFound>No data found</NoDataFound>
      ) : null}

      {!loading && details._id ? (
        <>
          {details?._canVerify ? (
            <InfoBlk variant="primary">
              <div className="fs-val-md fw-semibold">
                <span className="bi bi-info-circle"></span>
                <span>
                  {" "}
                  Please verify and approve / reject the return request
                </span>
              </div>
            </InfoBlk>
          ) : null}

          <StoreReturnBasicInfo data={details.basicInfo} />

          <StoreReturnDealsTable
            data={details.deals}
            showAction={details._canVerify}
            callback={tblCb}
          />

          {details._canVerify ? (
            <AppCard noShadow={true}>
              <div className="row">
                <div className="col-auto align-self-center">
                  <div className="text-muted fs-val-md">
                    Warehouse <span className="text-danger">*</span>
                  </div>
                </div>
                <div className="col-3">
                  <select className="form-control" onChange={onWhChange}>
                    <option value="">Choose Warehouse </option>
                    {warehouses.map((e) => (
                      <option key={e._id} value={e._id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-auto ms-auto">
                  <button className="btn btn-danger me-2" onClick={reject}>
                    Reject
                  </button>
                  <button className="btn btn-success" onClick={approve}>
                    Approve
                  </button>
                </div>
              </div>
            </AppCard>
          ) : null}

          <RejectReasonModal
            show={rejectModal.show}
            callback={rejectModalCb}
            type={rejectModal.type}
            deal={rejectModal.deal}
          />

          <BusyLoader show={busyloader.show} />
        </>
      ) : null}
    </>
  );
}

export default StoreReturnVerify;

import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { startOfMonth } from "date-fns";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

const shipmentStatusOptions = [
  { value: "shipmentArrival", label: "Incoming Shipment" },
  { value: "delivered", label: "Shipment Received" },
];

const orderTypeOptions = [
  { value: "", label: "All" },
  { value: "myOrder", label: "My Order" },
  { value: "reqOrder", label: "Request Order" },
  { value: "networkOrder", label: "Network Order" },
];

function StoreShipmentFilter({ callback }) {
  const { register, control, getValues } = useForm({
    defaultValues: {
      status: "shipmentArrival",
      type: "",
      date: [startOfMonth(new Date()), new Date()],
    },
  });

  const [status] = useWatch({ control, name: ["status"] });

  const debounceSearch = useCallback(
    debounce((data) => {
      callback({ formData: data });
    }, 500),
    [callback]
  );

  const onSearchCb = () => {
    debounceSearch(getValues());
  };

  const triggerInputCb = () => {
    callback({ formData: getValues() });
  };

  const onDateCb = (onChange) => (data) => {
    onChange(data);
    callback({ formData: getValues() });
  };

  return (
    <div className="row g-2 align-items-center">
      <div className="col-md-3">
        <TextInput
          name="search"
          label="Search Box ID, Invoice, Order"
          register={register}
          placeholder="Search Box ID, Invoice, Order"
          callback={onSearchCb}
          gap={0}
        />
      </div>

      <div className="col-md-3">
        <SelectInput
          name="status"
          label="Shipment Status"
          register={register}
          placeholder="All Shipment Status"
          options={shipmentStatusOptions}
          callback={triggerInputCb}
          gap={0}
        />
      </div>

      {/* <div className="col-md-3">
        <SelectInput
          name="type"
          label="Order Type"
          register={register}
          placeholder="All Order Types"
          options={orderTypeOptions}
          callback={triggerInputCb}
          gap={0}
        />
      </div> */}
      {status === "delivered" && (
        <div className="col-md-3">
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DateInput
                label="Received On"
                callback={onDateCb(field.onChange)}
                value={field.value}
                placeholder="Select Date Range"
                template={2}
                gap={0}
                hideClose={true}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default StoreShipmentFilter;

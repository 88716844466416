import React, { useState, useCallback, useEffect, useRef } from "react";
import { useAttachAdditionalData } from "@sk/hooks";
import {
  AppCard,
  AppTitle,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";

const tableHeaders = [
  {
    label: "Sl No",
    width: "5%",
    key: "slNo",
    isCentered: true,
  },
  {
    label: "Status",
    width: "15%",
    key: "status",
  },
  {
    label: "Updated On",
    width: "20%",
    key: "updatedOn",
  },
  {
    label: "Updated By",
    width: "20%",
    key: "updatedBy",
  },
  {
    label: "Remark",
    width: "40%",
    key: "remark",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "refId",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const StoreReturnAuditLog = ({ auditLogs = [] }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState(() => auditLogs.slice(0, 10));

  const paginationRef = useRef({
    ...defaultPagination,
    totalRecords: auditLogs.length,
  });

  useEffect(() => {
    let tmp = [];
    setAdditionalData(data, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(data, tmp)]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      const d = auditLogs.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      );
      setData(d);
      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });
    },
    [attachAllData, auditLogs, setAdditionalData]
  );

  return (
    <AppCard title="Audit Logs">
      <table className="table table-striped table-sm">
        <TableHeader data={tableHeaders} noBg />
        <tbody className="fs-val-sm">
          {data.length == 0 ? (
            <tr>
              <td colSpan={tableHeaders.length}>
                <NoDataFound>No Audit logs found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {data.map((x, k) => (
            <tr key={x._id}>
              <td className="text-center">
                {paginationRef.current.startSlNo + k}
              </td>
              <td>{x.displayStatus}</td>
              <td>
                <DateFormatter date={x.loggedOn} />
              </td>
              <td>{x._user?.name || x.loggedBy}</td>
              <td>{x.remark}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
      />
    </AppCard>
  );
};

export default StoreReturnAuditLog;

import { yupResolver } from "@hookform/resolvers/yup";
import { LogisticsService } from "@sk/services";
import { Spinner, TextInput, Toaster } from "@sk/uis";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const validation = yup.object({
  driverName: yup.string().trim().required().label("Driver Name"),
  vehicleNo: yup.string().trim().required().label("Vehicle No."),
  mobile: yup
    .string()
    .required()
    .matches(/^[0-9]{10}$/, "Please provide valid Mobile No.")
    .label("Mobile No."),
});

const defaultFormData = {
  vehicleNo: "",
  driverName: "",
  mobile: "",
};

function StoreReturnAssignDriverModal({
  show = false,
  returnId = "",
  callback,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { ...defaultFormData },
    resolver: yupResolver(validation),
  });

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      reset(defaultFormData);
    }
  }, [reset, show]);

  const onSubmit = async (data) => {
    setSubmitting(true);
    const r = await LogisticsService.assignDriverToStoreReturn(returnId, data);
    setSubmitting(false);
    if (r.statusCode == 200) {
      callback({ action: "submitted" });
      Toaster.success("Submitted successfully");
    } else {
      Toaster.error("Failed to submit, please try again");
    }
  };

  const onVehicleNoChange = (e) => {
    e.target.value = e.target.value.toUpperCase();
  };

  const onClose = () => {
    if (submitting) {
      return;
    }
    callback({ action: "close" });
  };

  const onMobChange = (e) => {
    const v = e.target.value;
    if (v) {
      e.target.value = v.replace(/[^0-9]/gi, "");
    }
  };

  const onDriverNameChange = (e) => {
    const v = e.target.value;
    e.target.value = v.replace(/[^a-zA-Z\s]/g, "");
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton={true}>
        <Modal.Title className="mb-0">
          Assign Driver for #{returnId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TextInput
            register={register}
            name="driverName"
            label="Driver Name"
            isMandatory={true}
            error={errors.driverName?.message}
            callback={onDriverNameChange}
          />
        </div>
        <div>
          <TextInput
            register={register}
            name="vehicleNo"
            label="Vehicle No"
            isMandatory={true}
            error={errors.vehicleNo?.message}
            callback={onVehicleNoChange}
          />
        </div>
        <div>
          <TextInput
            register={register}
            name="mobile"
            label="Mobile No."
            isMandatory={true}
            error={errors.mobile?.message}
            callback={onMobChange}
            maxLength={10}
          />
        </div>
        <div className="text-end">
          <button
            disabled={submitting}
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit {submitting ? <Spinner isSmall={true} /> : null}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StoreReturnAssignDriverModal;

import { LogisticsService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  HighlightText,
  ImgRender,
  InfoPopover,
  KeyVal,
} from "@sk/uis";
import React, { useEffect, useState } from "react";

const StoreReturnDealsTableRow = ({
  data = {},
  index,
  showAction,
  callback,
}) => {
  const [locationInfo, setLocationInfo] = useState({
    areaName: "",
    rackName: "",
    binName: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await LogisticsService.getWhAreas(data.whInfo.id, {
          filter: { location: data.location },
        });
        const apiData = response.resp || [];

        // 1. Find matching area
        const matchedArea = apiData.find((area) => area._id === data.area);

        if (matchedArea) {
          // 2. Find matching rack within the matched area
          const matchedRack = matchedArea.racks.find(
            (rack) => rack._id === data.rack
          );

          if (matchedRack) {
            // 3. Find matching bin within the matched rack
            const matchedBin = matchedRack.bins.find(
              (bin) => bin._id === data.bin
            );

            if (matchedBin) {
              setLocationInfo({
                areaName: matchedArea.name,
                rackName: matchedRack.name,
                binName: matchedBin.name,
              });
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the API if the data prop is present
    if (data && data.area && data.rack && data.bin) {
      fetchData();
    }
  }, [data, data.area, data.bin, data.location, data.rack]);

  return (
    <tr className="fs-val-md">
      <td>{index + 1}</td>
      <td>
        <div className="d-flex">
          <div style={{ width: "60px" }} className="rounded me-1">
            <ImgRender assetId={data.images?.[0]} isAsset={true} />
          </div>
          <div className="align-self-center">
            <div className="mb-1 fw-semibold">{data.dealName}</div>
            <div>
              <span className="text-muted fs-val-sm">
                Deal ID: {data.dealId}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <KeyVal
          label="MRP"
          labelCol="col-5"
          contentCol="col-7"
          className="mb-1"
          noBottomSpace={true}
        >
          : <Amount value={data.mrp} />
        </KeyVal>
        <KeyVal
          label="Pur. Price"
          labelCol="col-5"
          contentCol="col-7"
          className="mb-1"
          noBottomSpace={true}
        >
          : <Amount value={data.purchasePrice || 0} />
        </KeyVal>
        <KeyVal
          label="Last Pur. On"
          labelCol="col-5"
          contentCol="col-7"
          className="mb-1"
          noBottomSpace={true}
        >
          :{" "}
          {data.lastIntakeOn ? (
            <DateFormatter date={data.lastIntakeOn} format={"dd MMM yyyy"} />
          ) : (
            "N/A"
          )}
        </KeyVal>
      </td>
      <td>
        <div className="mb-1">{data.invoiceInfo?.id || "N/A"}</div>
        {data.invoiceInfo?.date ? (
          <KeyVal label="Invoice Date" fwSize="sm" template="col">
            <div className="text-muted">
              <DateFormatter
                date={data.invoiceInfo.date}
                format={"dd MMM yyyy"}
              />
            </div>
          </KeyVal>
        ) : null}
      </td>
      <td>
        <KeyVal
          label="Requested Qty"
          labelCol="col-6"
          contentCol="col-6"
          noBottomSpace={true}
        >
          :{" "}
          <span className="text-danger">
            {data.requestedQuantity} {data.sellInLooseQty ? "kg" : "units"}
          </span>
        </KeyVal>

        {data.receivedQuantity ? (
          <KeyVal
            label="Received Qty"
            labelCol="col-6"
            contentCol="col-6"
            noBottomSpace={true}
          >
            : {data.receivedQuantity} {data.sellInLooseQty ? "kg" : "units"}
          </KeyVal>
        ) : null}

        {data.approvedQuantity ? (
          <KeyVal
            label="Approved Qty"
            labelCol="col-6"
            contentCol="col-6"
            noBottomSpace={true}
          >
            :{" "}
            <span className="text-success">
              {data.approvedQuantity} {data.sellInLooseQty ? "kg" : "units"}
            </span>
          </KeyVal>
        ) : null}
      </td>
      <td>{data.type}</td>
      {data._canShowLocInView ? (
        <td>
          <KeyVal
            noBottomSpace={true}
            label="Location"
            labelCol="col-4"
            contentCol="col-8"
          >
            : {data.location || "N/A"}
          </KeyVal>
          <KeyVal
            noBottomSpace={true}
            label="Area"
            labelCol="col-4"
            contentCol="col-8"
          >
            : {locationInfo.areaName || "N/A"}
          </KeyVal>
          <KeyVal
            noBottomSpace={true}
            label="Rack"
            labelCol="col-4"
            contentCol="col-8"
          >
            : {data.rack || "N/A"}
          </KeyVal>
          <KeyVal
            noBottomSpace={true}
            label="Bin"
            labelCol="col-4"
            contentCol="col-8"
          >
            : {data.bin || "N/A"}
          </KeyVal>
        </td>
      ) : null}

      <td>
        {data.assets?.length > 0 ? (
          <button
            className="btn btn-link p-0 fs-val-md"
            onClick={() => callback({ action: "viewAttachments", deal: data })}
          >
            Attachments ({data.assets.length})
          </button>
        ) : (
          "--"
        )}
      </td>
      {showAction ? (
        <td>
          {data.status == "Pending" ? (
            <>
              <button
                className="btn btn-sm btn-outline-danger action-btn"
                onClick={() =>
                  callback({ action: "rejectDeal", deal: data, index })
                }
              >
                Reject
              </button>
            </>
          ) : null}

          {data.status == "Rejected" ? (
            <div>
              <button
                className="btn btn-link fs-val-md p-0 text-danger align-middle"
                onClick={() =>
                  callback({
                    action: "cancelReject",
                    deal: data,
                    index,
                  })
                }
              >
                Cancel Reject
              </button>
              <span className="align-middle">
                <InfoPopover content={data._rejectRemarks} />
              </span>
            </div>
          ) : null}
        </td>
      ) : (
        <td>
          <HighlightText template={2} type={data._statusType}>
            {data.status}
          </HighlightText>
          {data.status === "Rejected" && data.rejectRemarks ? (
            <span className="align-middle">
              <InfoPopover content={data.rejectRemarks} />
            </span>
          ) : null}
        </td>
      )}
    </tr>
  );
};

export default StoreReturnDealsTableRow;

import { useFetchUrlQueryString } from "@sk/hooks";
import { LogisticsService, NavService } from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  InfoBlk,
  NoDataFound,
  PageInfo,
  PageLoader,
  TableHeader,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import produce from "immer";
import { each } from "lodash";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StoreReturnBasicInfo from "../components/StoreReturnBasicInfo";
import StoreReturnDriverInfo from "../components/StoreReturnDriverInfo";
import StoreReturnInwardDealRow from "../components/StoreReturnInwardDealRow";
import StoreReturnScan from "./components/StoreReturnScan";

const theaders = [
  {
    label: "Sl No",
    width: "5%",
  },
  {
    label: "Deal Name",
    width: "29%",
  },
  {
    label: "MRP",
    width: "10%",
  },
  {
    label: "Requested Qty",
    width: "10%",
  },
  {
    label: "Received Qty",
    width: "10%",
  },
  {
    label: "Location Details",
    width: "25%",
  },
  {
    label: "",
    key: "action",
    width: "20%",
  },
];

// create a style object for the scanned row
const scannedStyle = { backgroundColor: "#eeffee" };

function StoreReturnInward() {
  const router = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [busyloader, setBusyloader] = useState({ show: false });

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({
    basicInfo: {},
    deals: [],
    pids: [],
  });

  const scannedIndexRef = useRef(-1);

  const isVerification = query.verify == 1 ? true : false;

  const breadcrumbs = useMemo(() => {
    if (isVerification) {
      return [
        {
          name: "Home",
          link: "/auth/init",
        },
        {
          name: "Store Returns",
          link: "/logistics/store-returns",
        },
        {
          name: "Verify the Details",
        },
      ];
    } else {
      return [
        {
          name: "Home",
          link: "/auth/init",
        },
        {
          name: "Store Returns",
          link: "/logistics/store-returns",
        },
        {
          name: "Inward Stock",
        },
      ];
    }
  }, [isVerification]);

  const init = useCallback(
    async (id) => {
      setLoading(true);
      const r = await LogisticsService.getStoreReturns({ filter: { _id: id } });
      const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

      if (isVerification && d.status != "Inward Approval Pending") {
        setDetails({
          basicInfo: {},
          deals: [],
        });
        setLoading(false);
        return;
      }

      let b = {};
      [
        "_id",
        "name",
        "franchiseId",
        "createdAt",
        "_totalUnits",
        "_totalDeals",
        "status",
        "rejectRemarks",
        "statusType",
        "statusDescription",
        "displayStockUnitStr",
        "sellerId",
        "displayStatus",
        "franchiseInfo",
        "whName",
        "modifiedBy",
        "modifiedAt",
        "requestCreatedBy",
        "whInfo",
      ].forEach((e) => {
        b[e] = d[e];
      });
      setDetails({
        _id: d._id,
        basicInfo: b,
        deals: (d.deals || [])
          .filter((e) => e.status == "Approved")
          .map((e) => {
            if (isVerification) {
              e._scanned = true;
            }

            let t = { ...e, _enteredQty: "" };

            if (t.inwarded?.length > 0) {
              const l = t.inwarded[0]?.location;

              t.location = l?.name;
              t.area = l?.area;
              t.rack = l?.rack;
              t.bin = l?.bin;

              t._enteredQty = t.inwarded[0].qty;
            }

            return t;
          }),
        vehicleInfo: {
          ...(d?.vehicleInfo || {}),
          handoverSlips: d?.handoverSlips,
        },
        pids: (d.deals || [])
          .filter((e) => e.status == "Approved")
          .map((e) => e.productId),
        _raw: { ...d },
      });
      setLoading(false);
    },
    [isVerification]
  );

  useEffect(() => {
    if (query.id) {
      init(query.id);
    }
  }, [init, query]);

  const scanCb = (e) => {
    const barcode = e.barcode;

    if (!barcode) {
      Toaster.error("Please provide Barcode");
      return;
    }

    const i = details.deals.findIndex(
      (e) => e.barcode == barcode && !e._scanned
    );

    if (i != -1) {
      if (details.deals[i]._scanned) {
        Toaster.error(barcode + " already scanned");
        return;
      }

      scannedIndexRef.current = i;

      setDetails(
        produce((draft) => {
          draft.deals[i]._scanned = true;
          draft.deals[i]._highlight = true;
        })
      );

      const t = setTimeout(() => {
        clearTimeout(t);
        setDetails(
          produce((draft) => {
            draft.deals[i]._highlight = false;
          })
        );
      }, 1000);

      Toaster.success(barcode + " scanned successfully");
    } else {
      Toaster.error(barcode + " did not match any deal or invalid barcode");
    }

    // const d = e.data;
    // const ids = d.map((e) => e.id);

    // if (!d.length) {
    //   Toaster.error("Invalid Barcode");
    //   return;
    // }

    // const index = details.deals.findIndex((e) => {
    //   return !e._scanned && ids.indexOf(e.productId) != -1;
    // });

    // if (index == -1) {
    //   Toaster.error("Barcode did not match");
    //   return;
    // }

    // setDetails(
    //   produce((draft) => {
    //     draft.deals[index]._scanned = true;
    //     draft.deals[index]._barcode = e.barcode;
    //   })
    // );
  };

  const cancelScan = (index) => {
    setDetails(
      produce((draft) => {
        draft.deals[index]._scanned = false;
        draft.deals[index]._enteredQty = "";
        draft.deals[index]._barcode = "";
      })
    );
  };

  const proceed = async () => {
    let msg = "";
    each(details.deals, (e) => {
      if (!e._enteredQty) {
        msg = `Please provide received qty for "${e.dealName}"`;
      } else if (e._enteredQty > e.requestedQuantity) {
        msg = `Qty cannot be greater than ${e.requestedQuantity} for "${e.dealName}"`;
      } else if (!e.location) {
        msg = `Please choose Location for "${e.dealName}"`;
      } else if (!e.area) {
        msg = `Please choose Area for "${e.dealName}"`;
      } else if (!e.rack) {
        msg = `Please choose Rack for "${e.dealName}"`;
      } else if (!e.bin) {
        msg = `Please choose Bin for "${e.dealName}"`;
      } else if (!e.reason) {
        msg = `Please choose Reason for "${e.dealName}"`;
      } else {
        msg = "";
      }

      return msg ? false : true;
    });

    if (msg) {
      Toaster.error(msg);
      return;
    }

    let payload = {
      returnTo: details._raw.returnTo,
      whInfo: { _id: details._raw.whInfo.id }, //details._raw.whInfo,
      deals: details.deals.map((e) => {
        let inwarded = [
          {
            pId: e.productId,
            reason: e.reason,
            mrp: e.mrp,
            qty: e._enteredQty,
            barcode: e.barcode,
            dealId: e.dealId,
            location: {
              name: e.location,
              rack: e.rack,
              bin: e.bin,
              area: e.area,
            },
          },
        ];
        return {
          stockMasterId: e.stockMasterId,
          dealId: e.dealId,
          requestedQuantity: e.requestedQuantity,
          type: e.type,
          inwarded,
        };
      }),
    };

    let r;
    if (isVerification) {
      setBusyloader({ show: true });
      r = await LogisticsService.inwardStoreReturn(details._id, payload);
      setBusyloader({ show: false });
    } else {
      setBusyloader({ show: true });
      r = await LogisticsService.inwardStoreReturnWithScan(
        details._id,
        payload
      );
      setBusyloader({ show: false });
    }

    if (r.statusCode == 200) {
      if (!isVerification) {
        await Alert.alert({
          icon: "success",
          okText: "Done",
          title:
            "Items has been recevied successfully. Request will be sent for Inward Approval",
        });
      } else {
        await Alert.alert({
          icon: "success",
          title: "Inwarded successfully",
          okText: "Done",
        });
      }
      NavService.to(router, "/logistics/store-returns");
    } else {
      Toaster.error(r.resp?.message || "Failed to submit");
    }
  };

  const tblCb = (e) => {
    const action = e.action || "";

    const dataChangeAction = [
      "location",
      "area",
      "rack",
      "bin",
      "receivedQty",
      "reason",
    ];

    if (action == "split") {
      doSplit(e.index);
    }

    if (action == "removeSplit") {
      removeSplit(e.index, e.splitIndex);
    }

    if (dataChangeAction.indexOf(action) != -1) {
      updateRowData(action, e.index, e.formData, e.splitIndex);
    }

    if (action == "cancelScan") {
      cancelScan(e.index);
    }
  };

  const updateRowData = (action, mainIndex, newData, splitIndex) => {
    setDetails(
      produce((draft) => {
        let d = draft.deals[mainIndex];
        if (splitIndex >= 0) {
          d = draft.deals[mainIndex].splits[splitIndex];
        }

        if (action == "reason") {
          d.reason = newData.reason;
        }

        if (action == "receivedQty") {
          d._enteredQty = validateReceivedQty(
            newData.receivedQty,
            mainIndex
          ).qty;
        }

        if (action == "location") {
          d.location = newData.location;
          d.area = "";
          d.rack = "";
          d.bin = "";
        }

        if (action == "area") {
          d.area = newData.area;
          d.rack = "";
          d.bin = "";
        }

        if (action == "rack") {
          d.rack = newData.rack;
          d.bin = "";
        }

        if (action == "bin") {
          d.bin = newData.bin;
        }
      })
    );
  };

  const validateReceivedQty = (enteredQty, mainIndex) => {
    const reqQty = details.deals[mainIndex].requestedQuantity;
    let max = reqQty;

    if (details.deals[mainIndex]?.splits?.length > 0) {
      max -= details.deals[mainIndex]?.splits.reduce(
        (s, i) => (s += i._enteredQty || 0),
        0
      );
    }

    if (max < 0) {
      max = 0;
    }

    if (enteredQty < 0) {
      return { qty: "" };
    }

    if (enteredQty > max) {
      const r = max - enteredQty;
      return { qty: r < 0 ? "" : r };
    } else {
      return { qty: 1 * enteredQty };
    }
  };

  const doSplit = (index) => {
    const reqQty = details.deals[index].requestedQuantity;
    let rem = reqQty;

    if (details.deals[index]._enteredQty) {
      rem -= details.deals[index]._enteredQty;
    }

    if (details.deals[index]?.splits?.length > 0) {
      rem -= details.deals[index]?.splits.reduce(
        (s, i) => (s += i._enteredQty || 0),
        0
      );
    }

    if (rem <= 0) {
      Toaster.error("You cannot do split. Received qty got fulfilled");
      return;
    }

    if (details.deals[index]) {
      setDetails(
        produce((draft) => {
          const d = {
            ...details.deals[index],
            location: "",
            area: "",
            rack: "",
            bin: "",
          };

          if (!draft.deals[index]?.splits) {
            draft.deals[index].splits = [];
          }

          draft.deals[index].splits.push({ ...d });
        })
      );
    }
  };

  const removeSplit = (mainIndex, splitIndex) => {
    setDetails(
      produce((draft) => {
        draft.deals[mainIndex].splits.splice(splitIndex, 1);
      })
    );
  };

  return (
    <>
      <div className="pt-2"></div>

      {loading ? <PageLoader /> : null}

      {!loading && !details._id ? (
        <NoDataFound>No data to display</NoDataFound>
      ) : null}

      {!loading && details?._id ? (
        <>
          <PageInfo
            title={`Store Returns - ${
              isVerification ? "Verify Details" : "Inward Stock"
            } #${query?.id}`}
            breadcrumbs={breadcrumbs}
            navigate={router}
          />

          <InfoBlk variant="primary">
            <div className="fs-val-md fw-semibold">
              <span className="bi bi-info-circle"></span>
              <span>
                {isVerification
                  ? " Please verify the details and submit"
                  : " Please scan the barcode to enter received qty"}
              </span>
            </div>
          </InfoBlk>

          <div className="mb-4">
            <StoreReturnBasicInfo data={details.basicInfo} />
          </div>

          <StoreReturnDriverInfo data={details.vehicleInfo} />

          <div>
            <AppCard title="List of Deals">
              <table className="table">
                <TableHeader data={theaders} />
                <tbody>
                  {details.deals.map((e, k) => (
                    <Fragment key={e.dealId + ":" + k}>
                      <StoreReturnInwardDealRow
                        data={e}
                        index={k}
                        callback={tblCb}
                        style={
                          scannedIndexRef.current == k ? scannedStyle : null
                        }
                        isVerification={isVerification}
                      />
                      {(e?.splits || []).map((s, k1) => (
                        <StoreReturnInwardDealRow
                          key={e.dealId + ":" + k + ":" + k1}
                          data={s}
                          index={k}
                          callback={tblCb}
                          splitIndex={k1}
                          isSplit={true}
                          isVerification={isVerification}
                        />
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </AppCard>
          </div>

          <BusyLoader show={busyloader.show} />

          <div className="position-fixed bottom-0" style={{ width: "94%" }}>
            <div
              className={classNames(
                "row px-4 border rounded align-items-center",
                !isVerification ? "bg-secondary py-1" : "bg-white py-2"
              )}
            >
              <div className="col-4">
                {!isVerification ? (
                  <StoreReturnScan
                    callback={scanCb}
                    pids={details.pids}
                    sellerId={details.basicInfo?.sellerId}
                  />
                ) : null}
              </div>
              <div className="col text-end">
                <button
                  className={classNames(
                    "btn m-0",
                    isVerification ? "btn-primary" : "btn-light"
                  )}
                  onClick={proceed}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default StoreReturnInward;

import { LogisticsService } from "@sk/services";
import { Amount, DisplayUnit, KeyVal } from "@sk/uis";
import React, { useEffect, useRef, useState } from "react";

import styles from "../StoreReturn.module.scss";
import classNames from "classnames";
import produce from "immer";

const locations = LogisticsService.getLocations();

const prepareAreaOptions = (data) => {
  return data.map((e) => ({
    name: e.name,
    value: e._id,
    racks: e.racks || [],
  }));
};

const prepareRackOptions = (data, area) => {
  const selectedAreaData = data.find((rack) => rack._id === area);
  return (selectedAreaData?.racks || []).map((e) => ({
    name: e.name,
    value: e._id,
    bins: e.bins,
  }));
};

const prepareBinOptions = (data, area, rack) => {
  const selectedAreaData = data.find((a) => a._id === area);
  const selectedRackData = (selectedAreaData?.racks || []).find(
    (r) => r._id === rack
  );
  return (selectedRackData?.bins || []).map((e) => ({
    name: e.name,
    value: e._id,
  }));
};

const StoreReturnInwardDealRow = ({
  data,
  callback,
  index,
  isSplit,
  splitIndex,
  style,
  isVerification = false,
}) => {
  const [formData, setFormData] = useState({
    ...data,
    areaOptions: [],
    rackOptions: [],
    binOptions: [],
    reaons: LogisticsService.getReturnRemarks().map((e) => ({
      ...e,
      value: e.name,
    })),
  });

  const initalLoadRef = useRef(true);

  const areaRespRef = useRef([]);

  // Fetch options based on existing data (for initial autopopulation)
  useEffect(() => {
    const fetchInitialData = async () => {
      const r = await LogisticsService.getWhAreas(data?.whInfo?.id, {
        location: formData.location,
      });
      if (Array.isArray(r.resp) && r.resp.length > 0) {
        areaRespRef.current = r.resp;
        setFormData(
          produce((draft) => {
            draft.areaOptions = prepareAreaOptions(areaRespRef.current);
            draft.rackOptions = prepareRackOptions(
              areaRespRef.current,
              formData.area
            );
            draft.binOptions = prepareBinOptions(
              areaRespRef.current,
              formData.area,
              formData.rack
            );
          })
        );
      }

      // try {
      //   if (formData.location) {
      //   }
      // } catch (error) {
      //   console.error("Error fetching options:", error);
      // } finally {
      //   setInitialLoad(false); // Mark initial load complete
      // }
    };

    // Run only on initial load
    if (
      initalLoadRef.current &&
      formData.location &&
      formData.area &&
      formData.rack &&
      formData.bin
    ) {
      initalLoadRef.current = false;
      fetchInitialData();
    }
  }, [
    formData.location,
    formData.area,
    formData.rack,
    formData.bin,
    data?.whInfo?.id,
  ]);

  // Handle location change (user interaction)
  const handleLocationChange = async (event) => {
    const selectedLocation = event.target.value;
    const newFormData = {
      ...formData,
      location: selectedLocation,
      area: "",
      rack: "",
      bin: "",
      areaOptions: [],
      rackOptions: [],
      binOptions: [],
    };

    setFormData(newFormData);
    onDataChange("location", newFormData);

    if (selectedLocation) {
      try {
        // Fetch areas based on selected location
        const r = await LogisticsService.getWhAreas(data.whInfo?.id, {
          filter: { location: selectedLocation },
        });

        areaRespRef.current = r.resp;

        setFormData((prev) => ({
          ...prev,
          areaOptions: prepareAreaOptions(areaRespRef.current),
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Handle area change (user interaction)
  const handleAreaChange = async (event) => {
    const selectedArea = event.target.value;

    const newFormData = {
      ...formData,
      area: selectedArea,
      rack: "",
      bin: "",
      rackOptions: [],
      binOptions: [],
      reasons: [],
    };

    setFormData(newFormData);
    onDataChange("area", newFormData);

    if (selectedArea) {
      try {
        setFormData((prev) => ({
          ...prev,
          rackOptions: prepareRackOptions(areaRespRef.current, selectedArea),
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Handle rack change (user interaction)
  const handleRackChange = (event) => {
    const selectedRack = event.target.value;
    const newFormData = {
      ...formData,
      rack: selectedRack,
      bin: "",
      binOptions: prepareBinOptions(
        areaRespRef.current,
        formData.area,
        selectedRack
      ),
    };
    setFormData(newFormData);
    onDataChange("rack", newFormData);
  };

  // Handle bin change
  const handleBinChange = (event) => {
    const selectedBin = event.target.value;
    const newFormData = {
      ...formData,
      bin: selectedBin,
    };

    setFormData(newFormData);
    onDataChange("bin", newFormData);
  };

  // Handle MRP change
  const handleMrpChange = (event) => {
    const newMrp = event.target.value;
    const newFormData = {
      ...formData,
      mrp: newMrp,
    };

    setFormData(newFormData);
    onDataChange("mrp", newFormData);
  };

  const onDataChange = (action, newData) => {
    triggerCb(action, newData);
  };

  const doSplit = () => {
    triggerCb("split");
  };

  const removeSplit = () => {
    triggerCb("removeSplit");
  };

  const onReceivedQtyChange = (e) => {
    triggerCb("receivedQty", { receivedQty: e.target.value });
  };

  const handleReason = (e) => {
    setFormData(
      produce((draft) => {
        draft.reason = e.target.value;
      })
    );
    triggerCb("reason", { reason: e.target.value });
  };

  const cancelScan = () => {
    triggerCb("cancelScan");
  };

  const triggerCb = (action, newData = {}) => {
    const d = { ...newData };

    if (d?.areaOptions) {
      delete d.areaOptions;
    }

    if (d?.rackOptions) {
      delete d.rackOptions;
    }

    if (d?.binOptions) {
      delete d.binOptions;
    }

    if (d?.reaons) {
      delete d.reasons;
    }

    callback({
      action,
      index,
      isSplit,
      splitIndex,
      formData: d,
    });
  };

  return (
    <tr
      className={classNames(
        "fs-val-md position-relative",
        isSplit ? "bg-light" : "",
        data._highlight ? "animate__animated animate__pulse" : ""
      )}
      style={style}
    >
      <td>{!isSplit ? index + 1 : null}</td>
      <td>
        {!isSplit ? (
          <>
            {!isVerification && data._scanned ? (
              <span
                className={classNames(
                  "badge badge-soft-success fw-bold",
                  styles["scanned-badges"]
                )}
              >
                SCANNED
              </span>
            ) : null}
            <div className="mb-2">{data.dealName}</div>
            <div>
              <KeyVal label="Deal ID" noBottomSpace={true} fwSize="sm">
                : {data.dealId}
              </KeyVal>
              {!isVerification && data._scanned ? (
                <KeyVal label="Barcode" fwSize="sm">
                  : {data.barcode || "N/A"}
                </KeyVal>
              ) : null}
            </div>
          </>
        ) : null}
      </td>
      <td>
        {isSplit ? (
          <input
            type="number"
            className="form-control"
            value={formData.mrp || ""}
            onChange={handleMrpChange}
          />
        ) : (
          <Amount value={data.mrp} />
        )}
      </td>
      <td className="text-danger">
        <DisplayUnit
          value={data.requestedQuantity}
          isLoose={data.sellInLooseQty}
          noConv={true}
        />
      </td>
      <td>
        <div className="mb-2">
          <input
            className="form-control"
            placeholder="Recevied Qty"
            autoFocus
            value={data._enteredQty || ""}
            onChange={onReceivedQtyChange}
            type="number"
            min={0}
            disabled={!isVerification && !data._scanned}
          />
        </div>

        <div className="fs-val-sm text-black-50 ps-1">Reason*</div>
        <select
          className={classNames(styles["loc-select"])}
          value={formData.reason || ""}
          onChange={handleReason}
          disabled={!isVerification && !data._scanned}
        >
          <option value="">Select Reason</option>
          {(formData.reaons || [])?.map((r, k) => (
            <option key={k} value={r.name}>
              {r.name}
            </option>
          ))}
        </select>
      </td>
      <td>
        <div className="row">
          <div className="col-6 mb-2">
            <div className="fs-val-sm text-black-50 ps-1">Location*</div>
            <select
              className={classNames(styles["loc-select"])}
              value={formData.location || ""}
              onChange={handleLocationChange}
              disabled={!isVerification && !data._scanned}
            >
              <option value="">Select Location</option>
              {locations?.map((location, k) => (
                <option key={k} value={location.name}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <div className="fs-val-sm text-black-50 ps-1">Area*</div>
            <select
              className={classNames(styles["loc-select"])}
              value={formData.area || ""}
              onChange={handleAreaChange}
              disabled={!isVerification && !data._scanned}
            >
              <option value="">Select Area</option>
              {formData.areaOptions?.map((area) => (
                <option key={area.value} value={area.value}>
                  {area.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <div className="fs-val-sm text-black-50 ps-1">Rack*</div>
            <select
              className={classNames(styles["loc-select"])}
              value={formData.rack || ""}
              onChange={handleRackChange}
              disabled={!isVerification && !data._scanned}
            >
              <option value="">Select Rack</option>
              {formData.rackOptions?.map((rack) => (
                <option key={rack.value} value={rack.value}>
                  {rack.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <div className="fs-val-sm text-black-50 ps-1">Bin*</div>
            <select
              className={classNames(styles["loc-select"])}
              value={formData.bin || ""}
              onChange={handleBinChange}
              disabled={!isVerification && !data._scanned}
            >
              <option value="">Select Bin</option>
              {formData.binOptions?.map((bin) => (
                <option key={bin.value} value={bin.value}>
                  {bin.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </td>
      <td className="text-center">
        {isSplit ? (
          <button className="btn btn-sm btn-danger" onClick={removeSplit}>
            Remove Split
          </button>
        ) : null}

        {0 && !isSplit ? (
          <button onClick={doSplit} className="btn btn-secondary btn-sm">
            Split
          </button>
        ) : null}

        <div>
          {!isVerification && data._scanned ? (
            <button
              className="btn btn-link p-0 fs-val-sm text-danger"
              onClick={cancelScan}
            >
              Cancel Scan
            </button>
          ) : null}
        </div>
      </td>
    </tr>
  );
};

export default StoreReturnInwardDealRow;

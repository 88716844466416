import { DealService, LogisticsService, ProductService } from "@sk/services";
import { BusyLoader, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

const getData = async (barcode) => {
  const a = await ProductService.getProducts({
    filter: {
      barcode,
    },
  });
  console.log(a);
  return a;
};

function StoreReturnScan({ callback, pids = [], sellerId = "" }) {
  const { register, setValue } = useForm({
    defaultValues: { scan: "" },
  });

  const [loading, setLoading] = useState(false);

  const onInpChange = useCallback(
    debounce(async (e) => {
      const b = (e.target.value || "").trim();

      callback({ barcode: b });

      const t = setTimeout(() => {
        clearTimeout(t);
        setValue("barcode", "");
      }, 1000);

      // if (!b) {
      //   setLoading(false);
      //   return;
      // }

      // setLoading(true);

      // const r = await LogisticsService.getStoreReturnBarcode(b, pids, sellerId);

      // setLoading(false);

      // setValue("scan", "");

      // callback({ data: Array.isArray(r.resp) ? r.resp : [], barcode: b });
    }, 800),
    [callback]
  );

  return (
    <div>
      <div className="fs-val-md text-white mb-1">Barcode Scan</div>
      <TextInput
        register={register}
        name="scan"
        placeholder="Scan barcode here..."
        callback={onInpChange}
        groupContent={
          <span className="px-2">
            <i className="bi fs-val-lg bi-upc-scan"></i>
          </span>
        }
      />
      <BusyLoader show={loading} />
    </div>
  );
}

export default StoreReturnScan;

import { LogisticsService } from "@sk/services";
import { DateInput, SelectInput } from "@sk/uis";
import { Offcanvas, OffcanvasBody } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const canvasStyle = { width: "30%" };

const defaultFormData = {
  status: "all",
  createdAt: [],
  vendor: [],
};

const statusOptions = LogisticsService.getStoreReturnStatus();
statusOptions.unshift({ label: "All", value: "all" });

function StoreReturnsAdvanceFilterModal({ show = false, callback }) {
  const { register, control, reset, getValues } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const onHide = () => {
    callback({ action: "close" });
  };

  const onApply = () => {
    callback({ action: "apply", formData: getValues() });
  };

  const onReset = () => {
    reset({ ...defaultFormData });
    callback({ action: "reset", formData: getValues() });
  };

  return (
    <Offcanvas show={show} placement="end" style={canvasStyle} onHide={onHide}>
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Advance Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <OffcanvasBody>
        <SelectInput
          label="Choose Status"
          register={register}
          name="status"
          options={statusOptions}
        />

        <Controller
          name="createdAt"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateInput label="Created On" value={value} callback={onChange} />
          )}
        />

        <div className="position-absolute bottom-0 left-0 right-0 text-end p-4 w-100">
          <button className="btn btn-warning me-2" onClick={onReset}>
            Reset
          </button>
          <button className="btn btn-primary" onClick={onApply}>
            Apply
          </button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default StoreReturnsAdvanceFilterModal;

import {
  Amount,
  AppCard,
  HighlightText,
  ImgPreviewModal,
  TableHeader,
} from "@sk/uis";
import { Fragment, useMemo, useState } from "react";
import StoreReturnDealsTableRow from "./StoreReturnDealsTableRow";

const theaders = [
  {
    label: "Sl No",
    width: "3%",
    key: "slNo",
  },
  {
    label: "Deal Name",
    width: "20%",
    key: "dealName",
  },
  {
    label: "Pricing",
    width: "16%",
    key: "mrp",
  },
  {
    label: "Invoice",
    width: "8%",
    key: "invoiceInfo",
  },
  {
    label: "Quantity Details",
    width: "15%",
    key: "quantityDetails",
  },
  {
    label: "Reason",
    key: "reason",
    width: "8%",
  },
  {
    label: "Locations",
    key: "location",
    width: "14%",
  },
  {
    label: "Uploads",
    width: "8%",
    key: "uploads",
  },
  {
    label: "Status",
    key: "status",
    width: "8%",
  },
  {
    label: "Action",
    key: "action",
    width: "12%",
  },
];

function StoreReturnDealsTable({
  data = [],
  callback,
  showAction = false,
  status = "",
}) {
  const [imgPreviewModal, setImgPreviewModal] = useState({
    show: false,
    imgs: [],
  });

  const totals = useMemo(() => {
    return {
      approved: data.filter((e) => e.status == "Approved").length,
      rejected: data.filter((e) => e.status == "Rejected").length,
      totalMrp: data.reduce((acc, e) => acc + e.mrp * e._finalQty, 0),
      totalPrice: data.reduce(
        (acc, e) => acc + e.purchasePrice * e._finalQty,
        0
      ),
      totalRequestedQty: data
        .filter((e) => !e.sellInLooseQty)
        .reduce((acc, e) => acc + e.requestedQuantity, 0),
      totalRequestedLooseQty: data
        .filter((e) => e.sellInLooseQty)
        .reduce((acc, e) => acc + e.requestedQuantity, 0),
      totalReceivedQty: data
        .filter((e) => !e.sellInLooseQty)
        .reduce((acc, e) => acc + e.receivedQuantity, 0),
      totalReceivedLooseQty: data
        .filter((e) => e.sellInLooseQty)
        .reduce((acc, e) => acc + e.receivedQuantity, 0),
      totalApprovedQty: data
        .filter((e) => !e.sellInLooseQty)
        .reduce((acc, e) => acc + e.approvedQuantity, 0),
      totalApprovedLooseQty: data
        .filter((e) => e.sellInLooseQty)
        .reduce((acc, e) => acc + e.approvedQuantity, 0),
    };
  }, [data]);

  const headers = useMemo(() => {
    const filteredHeaders = theaders.filter((e) => {
      if (e.key == "action") {
        return showAction;
      }
      if (showAction && e.key == "status") {
        return false;
      }
      if (e.key == "location") {
        return ["Inward Approval Pending", "Inwarded"].indexOf(status) != -1;
      } else {
        return true;
      }
    });

    // Check if the location column is included
    const hasLocationColumn = filteredHeaders.some(
      (header) => header.key === "location"
    );

    return filteredHeaders.map((header) => {
      // Increase width if location column is not included
      if (header.key === "quantityDetails" || header.key === "reason") {
        return {
          ...header,
          width: hasLocationColumn
            ? header.width
            : `${parseFloat(header.width) + 10}%`, // Increase width by 10%
        };
      }
      return header;
    });
  }, [showAction, status]);

  const imgPreviewModalCb = () => {
    setImgPreviewModal({ show: false, imgs: [] });
  };

  const viewAttachments = (assets) => {
    setImgPreviewModal({ imgs: assets.map((e) => ({ id: e })), show: true });
  };

  const triggerCb = (e) => {
    if (e.action == "viewAttachments") {
      viewAttachments(e.deal.assets);
    } else {
      callback(e);
    }
  };

  return (
    <>
      <AppCard title={"List of Deals (" + data.length + ")"} noShadow={true}>
        {totals.approved > 0 || totals.rejected > 0 ? (
          <div className="mb-1">
            {totals.approved > 0 ? (
              <HighlightText template={2} type={"success"} className="me-3">
                Approved: {totals.approved}
              </HighlightText>
            ) : null}

            {totals.rejected > 0 ? (
              <HighlightText template={2} type={"danger"}>
                Rejected: {totals.rejected}
              </HighlightText>
            ) : null}
          </div>
        ) : null}

        <table className="table table-sm mb-0">
          <TableHeader data={headers} noBg />
          <tbody>
            {data.map((e, k) => (
              <Fragment key={e.dealId + ":" + k}>
                <StoreReturnDealsTableRow
                  index={k}
                  data={e}
                  showAction={showAction}
                  callback={triggerCb}
                />
              </Fragment>
            ))}
            <tr className="fs-val-md bg-light">
              <td className="fw-bold"></td>
              <td></td>
              <td colSpan={2} className="py-2">
                <span className="me-3">
                  <span className="text-black-50">Total MRP: </span>
                  <Amount value={totals.totalMrp} />
                </span>
                <span className="me-3">
                  <span className="text-black-50">Total Pur. Price: </span>
                  <Amount value={totals.totalPrice} />
                </span>
              </td>

              <td colSpan={headers.length - 4}>
                <span className="me-3">
                  <span className="text-black-50">Requested: </span>
                  <span className="text-danger">
                    {totals.totalRequestedQty ? (
                      <> {totals.totalRequestedQty} units </>
                    ) : null}
                    {totals.totalRequestedLooseQty ? (
                      <> {totals.totalRequestedLooseQty} kg </>
                    ) : null}
                  </span>
                </span>

                {totals.totalReceivedQty || totals.totalReceivedLooseQty ? (
                  <span className="ms-1 me-3">
                    <span className="text-black-50">Received: </span>
                    {totals.totalReceivedQty ? (
                      <> {totals.totalReceivedQty} units </>
                    ) : null}
                    {totals.totalReceivedLooseQty ? (
                      <> {totals.totalReceivedLooseQty} kg </>
                    ) : null}
                  </span>
                ) : null}

                {totals.totalApprovedQty || totals.totalApprovedLooseQty ? (
                  <span className="ms-1 me-3">
                    <span className="text-black-50">Approved: </span>
                    <span className="text-success">
                      {totals.totalApprovedQty ? (
                        <> {totals.totalApprovedQty} units </>
                      ) : null}
                      {totals.totalApprovedLooseQty ? (
                        <> {totals.totalApprovedLooseQty} kg </>
                      ) : null}
                    </span>
                  </span>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </AppCard>

      <ImgPreviewModal
        assets={imgPreviewModal.imgs}
        show={imgPreviewModal.show}
        isAsset={true}
        handleClose={imgPreviewModalCb}
      />
    </>
  );
}

export default StoreReturnDealsTable;

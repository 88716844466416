import { AppCard, ImgPreviewModal, KeyVal } from "@sk/uis";
import React, { useState } from "react";

const StoreReturnDriverInfo = ({ data }) => {
  const [imgPreviewModal, setImgPreviewModal] = useState({
    show: false,
    imgs: [],
  });

  const viewHandoverSlip = () => {
    setImgPreviewModal({
      imgs: data.handoverSlips.map((e) => ({ id: e })),
      show: true,
    });
  };

  const imgPreviewModalCb = () => {
    setImgPreviewModal({ show: false, imgs: [] });
  };

  return (
    <>
      {data?.driverName ? (
        <div className="mb-4">
          <AppCard noShadow={true} title="Driver Details">
            <div className="row">
              <div className="col-3">
                <KeyVal
                  label="Driver Name"
                  labelCol="col-5"
                  contentCol="col-7"
                  noBottomSpace={true}
                >
                  : {data?.driverName}
                </KeyVal>
              </div>
              <div className="col-2">
                <KeyVal
                  label="Mobile No."
                  noBottomSpace={true}
                  labelCol="col-5"
                  contentCol="col-7"
                >
                  : {data?.mobile}
                </KeyVal>
              </div>
              <div className="col">
                <KeyVal
                  label="Vehicle No."
                  noBottomSpace={true}
                  labelCol="col-4"
                  contentCol="col-8"
                >
                  : {data?.vehicleNo}
                </KeyVal>
              </div>
              <div className="col">
                {data?.handoverSlips?.length > 0 ? (
                  <button
                    className="btn btn-link p-0 fs-val-md"
                    onClick={viewHandoverSlip}
                  >
                    View Handover Slips
                  </button>
                ) : null}
              </div>
            </div>
          </AppCard>
        </div>
      ) : null}

      <ImgPreviewModal
        assets={imgPreviewModal.imgs}
        show={imgPreviewModal.show}
        handleClose={imgPreviewModalCb}
        isAsset={true}
      />
    </>
  );
};

export default StoreReturnDriverInfo;

import { useFetchUrlQueryString } from "@sk/hooks";
import { LogisticsService, NavService } from "@sk/services";
import {
  Amount,
  BusyLoader,
  InfoBlk,
  NoDataFound,
  PageInfo,
  PageLoader,
  Rbac,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StoreReturnAuditLog from "../components/StoreReturnAuditLog";
import StoreReturnBasicInfo from "../components/StoreReturnBasicInfo";
import StoreReturnDealsTable from "../components/StoreReturnDealsTable";
import StoreReturnDriverInfo from "../components/StoreReturnDriverInfo";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Store Returns",
    link: "/logistics/store-returns",
  },
  {
    name: "Details",
  },
];

const rbac = {
  verify: ["VerifyStoreReturns"],
  verifyInward: ["VerifyInwardStoreReturns"],
};

function StoreReturnView() {
  const router = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [busyloader, setBusyloader] = useState({ show: false });

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({
    basicInfo: {},
    deals: [],
    auditLogs: [],
  });

  const init = async (id) => {
    setLoading(true);
    const r = await LogisticsService.getStoreReturns({ filter: { _id: id } });
    const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};
    let b = {};
    [
      "_id",
      "name",
      "franchiseId",
      "createdAt",
      "displayStockUnitStr",
      "_totalDeals",
      "status",
      "rejectRemarks",
      "statusType",
      "statusDescription",
      "handoverSlips",
      "whName",
      "franchiseInfo",
      "displayStatus",
      "returnAmount",
      "modifiedBy",
      "modifiedAt",
      "requestCreatedBy",
      "whInfo",
    ].forEach((e) => {
      b[e] = d[e];
    });
    setDetails({
      _id: d._id,
      basicInfo: b,
      deals: (d.deals || []).map((e) => {
        let t = { ...e };

        if (t.inwarded?.length > 0) {
          const l = t.inwarded[0].location;
          t.location = l?.name;
          t.area = l?.area;
          t.rack = l?.rack;
          t.bin = l?.bin;
          t.inwarded.splice(0, 1);
        }

        return { ...t };
      }),
      vehicleInfo: {
        ...(d?.vehicleInfo || {}),
        handoverSlips: d?.handoverSlips,
      },
      auditLogs: (d?.auditLog || []).reverse(),
      _canVerifyInward: d?._canVerifyInward,
      _canVerify: d?._canVerify,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (query.id) {
      init(query.id);
    }
  }, [query]);

  const verifyInward = () => {
    NavService.to(router, "/logistics/store-returns/inward", {
      id: details._id,
      verify: 1,
    });
  };

  const verify = () => {
    NavService.to(router, "/logistics/store-returns/verify", {
      id: details._id,
    });
  };

  return (
    <>
      <div className="pt-2"></div>

      <div className="row mb-2">
        <div className="col align-self-end">
          <PageInfo
            title={"Store Returns Details - #" + query?.id}
            breadcrumbs={breadcrumbs}
            navigate={router}
            noMargin={true}
          />
        </div>
        {details._canVerifyInward ? (
          <div className="col-auto">
            <Rbac roles={rbac.verifyInward}>
              <button
                className="btn btn-success btn-sm fs-val-md"
                onClick={verifyInward}
              >
                Verify & Inward
              </button>
            </Rbac>
          </div>
        ) : null}

        {details._canVerify ? (
          <div className="col-auto">
            <Rbac roles={rbac.verify}>
              <button
                className="btn btn-success btn-sm fs-val-md"
                onClick={verify}
              >
                Verify Request
              </button>
            </Rbac>
          </div>
        ) : null}
      </div>

      {loading ? <PageLoader /> : null}

      {!loading && !details?._id ? (
        <NoDataFound>No data found</NoDataFound>
      ) : null}

      {!loading && details._id ? (
        <>
          {details?.basicInfo?.returnAmount ? (
            <InfoBlk variant="success">
              <i className="bi bi-check-circle me-2 fs-val-lg"></i>
              The return is complete, and{" "}
              <span className="fw-bold">
                <Amount value={details.basicInfo?.returnAmount || 0} />
              </span>{" "}
              has been credited to the retailer.
            </InfoBlk>
          ) : null}

          {details?.basicInfo?.rejectRemarks ? (
            <InfoBlk variant="danger">
              <strong>Reject Remarks:</strong>{" "}
              {details?.basicInfo?.rejectRemarks}
            </InfoBlk>
          ) : null}

          <div className="mb-4">
            <StoreReturnBasicInfo data={details.basicInfo} />
          </div>

          <StoreReturnDriverInfo data={details.vehicleInfo} />

          <StoreReturnDealsTable
            data={details.deals}
            status={details.basicInfo?.status}
          />

          <StoreReturnAuditLog auditLogs={details.auditLogs} />

          <BusyLoader show={busyloader.show} />
        </>
      ) : null}
    </>
  );
}

export default StoreReturnView;

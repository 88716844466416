import { CommonService, LogisticsService } from "@sk/services";
import { AppCard, AppliedFilterLabel, PageInfo } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import StoreReturnFilter from "./components/StoreReturnFilter";
import StoreReturnSummary from "./components/StoreReturnSummary";
import StoreReturnTable from "./components/StoreReturnTable";
import StoreReturnAssignDriverModal from "../modals/StoreReturnAssignDriverModal";
import { merge } from "lodash";
import produce from "immer";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Store Returns",
  },
];

const getStatusInfo = (value) => {
  const statusList = LogisticsService.getStoreReturnStatus();
  return statusList.find((status) => status.value === value) || {};
};

const defaultSummaryData = [
  {
    label: "Total Returns",
    value: 0,
    color: "primary",
    key: "total",
    description: "Total number of store returns across all statuses",
  },
  {
    label: "Approval Pending",
    value: 0,
    color: "info",
    key: "approvalPending",
    description: getStatusInfo("Approval Pending").description,
  },
  {
    label: "Pending Pickup",
    value: 0,
    color: "primary",
    key: "pendingPickup",
    description: getStatusInfo("Approved").description,
  },
  {
    label: "Pickup Ready",
    value: 0,
    color: "warning",
    key: "pickupReady",
    description: getStatusInfo("Pickup Ready").description,
  },
  {
    label: "Yet to Inward",
    value: 0,
    color: "secondary",
    key: "yetToInward",
    description: getStatusInfo("Handover").description,
  },
  // {
  //   label: "Closed",
  //   value: 0,
  //   color: "light",
  //   key: "closed",
  //   description: getStatusInfo("Closed").description,
  // },
  {
    label: "Inward Approval Pending",
    value: 0,
    color: "info",
    key: "inwardApprovalPending",
    description: getStatusInfo("Inward Approval Pending").description,
  },
  {
    label: "Inwarded",
    value: 0,
    color: "success",
    key: "inwarded",
    description: getStatusInfo("Inwarded").description,
  },
  {
    label: "Rejected",
    value: 0,
    color: "danger",
    key: "rejected",
    description: getStatusInfo("Rejected").description,
  },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const filterConfig = {
  search: { label: "Search by ID" },
  franchise: { label: "Store", valuePath: "0.label" },
  status: { label: "Status" },
  createdAt: { label: "Created On", type: "dateRange" },
  vendor: { label: "Vendor", valuePath: "0.label" }, // Assuming vendor is a multi-select like franchise
};

const getData = async (params) => {
  const r = await LogisticsService.getStoreReturns(params);
  const d = (Array.isArray(r.resp) ? r.resp : []).map((e) => {
    return {
      _id: e._id,
      franchise: {
        name: e.name,
        id: e.franchiseId,
      },
      totalItems: e.deals.length,
      createdAt: e.createdAt,
      status: e.status,
      _canVerify: e._canVerify,
      returnTo: e.returnTo,
      _canAssignDriver: e._canAssignDriver,
      _canInward: e._canInward,
      statusType: e.statusType,
      statusDescription: e.statusDescription,
      displayStockUnitStr: e.displayStockUnitStr,
      displayStatus: e.displayStatus,
      whInfo: e.whInfo,
    };
  });
  return { data: d };
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  const r = await LogisticsService.getStoreReturnsCount(p);
  return { count: r.count };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  const s = (filter?.search || "").trim();

  if (s) {
    p.filter.$or = [
      { _id: { $regex: s, $options: "gi" } },
      { "deals.dealId": { $regex: s, $options: "gi" } },
      { "deals.dealName": { $regex: s, $options: "gi" } },
    ];
  }

  if (filter?.franchise?.length > 0) {
    const fid = filter.franchise[0].value._id;
    p.filter["sellerInfo.fId"] = fid;
  }

  if (filter.type) {
    p.filter.groupType = filter.type;
  }

  if (filter.status && filter.status != "all") {
    p.filter.status = filter.status;
  }

  if (!Object.keys(filter).length) {
    delete p.filter;
  }
  return p;
};

function StoreReturnsList() {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [summary, setSummary] = useState([...defaultSummaryData]);

  const [assignDriverModal, setAssignDriverModal] = useState({
    show: false,
    index: -1,
  });

  const [appliedFilters, setAppliedFilters] = useState([]);

  const filterDataRef = useRef({});

  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 15,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 15,
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    updateAppliedFilters(filterDataRef.current);

    loadList();

    loadSummary();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const onFilterCb = (e) => {
    if (e.action == "apply" || e.action == "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...e.formData };
      applyFilter();
    }
  };

  const tblCb = (e) => {
    if (e.action == "assignDriver") {
      setAssignDriverModal({ show: true, id: e.data.id, index: e.data.index });
    }
  };

  const returnTblCb = () => {
    setAssignDriverModal({ show: false, id: "" });
  };

  const loadSummary = async () => {
    let p = prepareFilterParams(filterDataRef.current, paginationRef.current);
    delete p.page;
    delete p.count;

    let arr = [
      getCount(p),
      getCount(merge({}, p, { filter: { status: "Approval Pending" } })),
      getCount(merge({}, p, { filter: { status: "Approved" } })),
      getCount(merge({}, p, { filter: { status: "Pickup Ready" } })),
      getCount(merge({}, p, { filter: { status: "Handover" } })),
      getCount(merge({}, p, { filter: { status: "Inwarded" } })),
      getCount(merge({}, p, { filter: { status: "Closed" } })),
      getCount(merge({}, p, { filter: { status: "Rejected" } })),
      getCount(merge({}, p, { filter: { status: "Inward Approval Pending" } })),
    ];

    const r = await Promise.all(arr);

    setSummary(
      produce((draft) => {
        draft.forEach((e, k) => {
          draft[k].value = r[k]?.count;
        });
      })
    );
  };

  const assignDriverModalCb = (e) => {
    const index = assignDriverModal.index;

    setAssignDriverModal({ show: false, index: -1 });

    if (e.action == "submitted") {
      setData(
        produce((draft) => {
          draft[index].status = "Pickup Ready";
          draft[index]._canAssignDriver = false;
        })
      );
    }
  };

  const updateAppliedFilters = (formData) => {
    const newFilters = CommonService.prepareAppliedFilterLabels(
      filterConfig,
      formData
    );

    // Filter out the 'all' status
    const filteredFilters = newFilters.filter(
      (filter) =>
        !(
          filter.key === "status" &&
          (filter.value === "all" || filter.value === "All")
        )
    );

    setAppliedFilters(filteredFilters);
  };

  return (
    <>
      <div className="pt-2"></div>

      <PageInfo title="Store Returns" breadcrumbs={breadcrumbs} />

      <div>
        <StoreReturnSummary summary={summary} />
      </div>

      <AppCard>
        <StoreReturnFilter callback={onFilterCb} />
      </AppCard>

      <AppCard>
        <AppliedFilterLabel labels={appliedFilters} className="mb-2" />

        <StoreReturnTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          callback={tblCb}
        />
      </AppCard>

      <StoreReturnAssignDriverModal
        show={assignDriverModal.show}
        returnId={assignDriverModal.id}
        callback={assignDriverModalCb}
      />
    </>
  );
}

export default StoreReturnsList;

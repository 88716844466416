// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TdijTgT7UILd1x2i\\+Cd19A\\=\\={position:relative;width:100%}._8YDoOYMiRd6YNewVCyXHIg\\=\\={width:100%;font-size:.75rem;padding:.25rem .1rem;border:none;border-bottom:1px solid #ccc;background-color:rgba(0,0,0,0);appearance:none;cursor:pointer}._8YDoOYMiRd6YNewVCyXHIg\\=\\=:focus{outline:none;border-bottom-color:#007bff}._8YDoOYMiRd6YNewVCyXHIg\\=\\=::after{content:\"▼\";position:absolute;top:50%;right:.5rem;transform:translateY(-50%);pointer-events:none}", "",{"version":3,"sources":["webpack://./src/pages/store-returns/verify-inward/components/StoreReturnVerifyInwardRow.module.scss"],"names":[],"mappings":"AAAA,6BAAA,iBACI,CAAA,UACA,CAAA,6BAIJ,UACI,CAAA,gBACA,CAAA,oBACA,CAAA,WACA,CAAA,4BACA,CAAA,8BACA,CAAA,eACA,CAAA,cACA,CAAA,mCAEA,YACI,CAAA,2BACA,CAAA,oCAGJ,WACI,CAAA,iBACA,CAAA,OACA,CAAA,WACA,CAAA,0BACA,CAAA,mBACA","sourcesContent":[".custom-select-container {\n    position: relative;\n    width: 100%;\n}\n\n\n.custom-select {\n    width: 100%;\n    font-size: 0.75rem;\n    padding: 0.25rem 0.1rem;\n    border: none;\n    border-bottom: 1px solid #ccc;\n    background-color: transparent;\n    appearance: none;\n    cursor: pointer;\n\n    &:focus {\n        outline: none;\n        border-bottom-color: #007bff;\n    }\n\n    &::after {\n        content: '\\25BC';\n        position: absolute;\n        top: 50%;\n        right: 0.5rem;\n        transform: translateY(-50%);\n        pointer-events: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-select-container": "TdijTgT7UILd1x2i+Cd19A==",
	"custom-select": "_8YDoOYMiRd6YNewVCyXHIg=="
};
export default ___CSS_LOADER_EXPORT___;

import { UserService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  HighlightText,
  InfoPopover,
  KeyVal,
} from "@sk/uis";
import { useEffect, useState } from "react";

function StoreReturnBasicInfo({ data = {} }) {
  const [lastModifiedBy, setLastModifiedBy] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const r = await UserService.getList({
        filter: { _id: data.modifiedBy },
        select: "name",
      });
      if (r.resp.length > 0) {
        setLastModifiedBy(r.resp.find((e) => e._id === data.modifiedBy));
      }
    };

    if (data.modifiedBy) {
      fetchData();
    }
  }, [data.modifiedBy]);

  return (
    <AppCard noShadow={true} title="Basic Info">
      <div className="row">
        <div className="col-3">
          <div className="fs-val-sm mb-2">Store Details</div>
          <div className="fs-val-md text-primary mb-1 fw-bold">{data.name}</div>

          <div className="fs-val-sm text-muted">
            <div>FID: {data.franchiseId}</div>
            <div>
              {data.franchiseInfo?.town}, {data.franchiseInfo?.disrict},
              {data.franchiseInfo?.state} - {data.franchiseInfo?.pincode}
            </div>
          </div>
        </div>
        <div className="col-3">
          <KeyVal label="Status" labelCol="col-4" contentCol="col-8">
            :{" "}
            <HighlightText isInline={true} template={2} type={data.statusType}>
              {data.displayStatus}
            </HighlightText>
            {data.statusDescription && (
              <InfoPopover content={data.statusDescription} placement="top" />
            )}
          </KeyVal>
          <KeyVal label="Return To" labelCol="col-4" contentCol="col-8">
            : StoreKing
          </KeyVal>
          <KeyVal label="Total Items" labelCol="col-4" contentCol="col-8">
            : <span className="text-danger">{data._totalDeals} items</span>
          </KeyVal>
          <KeyVal
            label="Total Units"
            noBottomSpace={true}
            labelCol="col-4"
            contentCol="col-8"
          >
            : <span className="text-danger">{data.displayStockUnitStr}</span>
          </KeyVal>
        </div>
        <div className="col">
          {/* <KeyVal label="Vendor" className="align-items-start">
            <div className="d-flex">
              <div className="me-1">: </div>
              <div>
                <span>Ram Distributors (V10202), </span>
                <span className="fs-val-sm text-muted">
                  kumbalagodu Kengeri, Karantaka - 560040
                </span>
              </div>
            </div>
          </KeyVal> */}

          <KeyVal label="Created On" labelCol="col-4" contentCol="col-8">
            : <DateFormatter date={data.createdAt} />
          </KeyVal>

          <KeyVal label="Created By" labelCol="col-4" contentCol="col-8">
            : {data.requestCreatedBy || "--"}
          </KeyVal>

          <KeyVal label="Last Updated" labelCol="col-4" contentCol="col-8">
            : <DateFormatter date={data.modifiedAt} />
          </KeyVal>
          <KeyVal label="Last Updated By" labelCol="col-4" contentCol="col-8">
            : {lastModifiedBy.name || "--"}
          </KeyVal>
        </div>

        <div className="col">
          {data.whName ? (
            <KeyVal label="Warehouse" labelCol="col-4" contentCol="col-8">
              : {data.whName || "--"}
            </KeyVal>
          ) : null}

          {data.whName ? (
            <KeyVal label="Warehouse Address" template="col" fwSize="md">
              <div className="text-muted fs-val-md">
                {data.whInfo?.address?.door_no}, {data.whInfo?.address?.street},{" "}
                {data.whInfo?.district}, {data.whInfo?.state} -{" "}
                {data.whInfo?.address?.pincode}
              </div>
            </KeyVal>
          ) : null}
        </div>
      </div>
    </AppCard>
  );
}

export default StoreReturnBasicInfo;

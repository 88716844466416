import { AppSlide, AppSwiper, InfoPopover, SummaryCard } from "@sk/uis";
import classNames from "classnames";

const config = {
  slidesPerView: "auto",
  spaceBetween: 10,
  freeMode: true,
  mousewheel: {
    forceToAxis: true,
  },
};

function StoreReturnSummary({ summary = [] }) {
  return (
    <AppSwiper config={config}>
      {summary.map((e, index) => (
        <AppSlide key={e.key} autoWidth>
          <div className={classNames({ "pe-4": index == summary.length - 1 })}>
            <SummaryCard
              value={e.value}
              title={
                <span>
                  {e.label}
                  {e.description && (
                    <InfoPopover content={e.description} placement="top" />
                  )}
                </span>
              }
              template={3}
              isValueAmt={e.isValueAmt}
              amtDecimalPlace={0}
              valueColor={e.color}
            />
          </div>
        </AppSlide>
      ))}
    </AppSwiper>
  );
}

export default StoreReturnSummary;

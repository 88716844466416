import { EntitySearchInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import StoreReturnsAdvanceFilterModal from "../modals/StoreReturnsAdvanceFilterModal";

const defaultFormData = {
  search: "",
  franchise: [],
};

const franFilter = {
  filter: {
    groupType: "COCO",
  },
};

function StoreReturnFilter({ callback }) {
  const { control, register, getValues, setValue, reset } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [advFilterModal, setAdvFilterModal] = useState({ show: false });

  const onSearch = useCallback(
    debounce(() => {
      triggerCb();
    }, 800),
    []
  );

  const advFilterCb = (e) => {
    if (e.action == "reset") {
      reset();
    }
    setAdvFilterModal({ show: false });
    callback({ ...e, formData: { ...e.formData, ...getValues() } });
  };

  const onFranChange = (e) => {
    setValue("franchise", e);
    triggerCb();
  };

  const triggerCb = () => {
    callback({ action: "apply", formData: getValues() });
  };

  return (
    <>
      <div className="row">
        <div className="col-3">
          <TextInput
            label="Search"
            placeholder="Search by ID, Deal ID, Deal Name"
            register={register}
            name="search"
            callback={onSearch}
            gap={0}
          />
        </div>
        <div className="col-3">
          <Controller
            name="franchise"
            control={control}
            render={({ field: { value } }) => (
              <EntitySearchInput
                callback={onFranChange}
                value={value}
                label="Search by Store"
                type="franchise"
                uid="sr-fran"
                filterParams={franFilter}
                gap={0}
              />
            )}
          />
        </div>
        <div className="col-auto align-self-end">
          <button
            className="btn app-filter-btn"
            type="button"
            onClick={() => setAdvFilterModal({ show: true })}
          >
            <i className="bi bi-funnel"></i> FILTER
          </button>
        </div>
      </div>

      <StoreReturnsAdvanceFilterModal
        show={advFilterModal.show}
        callback={advFilterCb}
      />
    </>
  );
}

export default StoreReturnFilter;

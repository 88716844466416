import { useFetchUrlQueryString } from "@sk/hooks";
import { LogisticsService } from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  InfoBlk,
  NoDataFound,
  PageInfo,
  PageLoader,
  TableHeader,
  Toaster,
} from "@sk/uis";
import produce from "immer";
import { sum } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StoreReturnBasicInfo from "../components/StoreReturnBasicInfo";
import StoreReturnVerifyInwardRow from "./components/StoreReturnVerifyInwardRow";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Store Returns",
    link: "/logistics/store-returns",
  },
  {
    name: "Inward Stock",
  },
];

const theaders = [
  {
    label: "Sl No",
    width: "5%",
  },
  {
    label: "Deal",
    width: "22%",
  },
  {
    label: "Deal MRP",
    width: "10%",
  },
  {
    label: "Qty Details",
    width: "20%",
  },
  {
    label: "Enter Verified Qtys",
  },
  {
    label: "Locations",
    key: "action",
    width: "20%",
  },
  {
    label: "",
    key: "action",
    width: "10%",
  },
];

function StoreReturnVerifyInward() {
  const router = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [busyloader, setBusyloader] = useState({ show: false });

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({
    basicInfo: {},
    deals: [],
  });

  const rowRefs = useRef({});

  const init = async (id) => {
    setLoading(true);

    const r = await LogisticsService.getStoreReturns({ filter: { _id: id } });
    const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};
    let b = {};
    [
      "_id",
      "name",
      "franchiseId",
      "createdAt",
      "_totalUnits",
      "_totalDeals",
      "status",
      "rejectRemarks",
      "statusType",
      "statusDescription",
      "displayStockUnitStr",
    ].forEach((e) => {
      b[e] = d[e];
    });
    setDetails({
      _id: d._id,
      basicInfo: b,
      deals: (d.deals || []).map((e) => ({ ...e, _enteredQty: "" })),
      vehicleInfo: d?.vehicleInfo || {},
    });
    setLoading(false);
  };

  useEffect(() => {
    if (query.id) {
      init(query.id);
    }
  }, [query]);

  const handleSubmit = () => {
    let errorMessage = "";
    let errorRowId = null;

    for (const deal of details.deals) {
      // Check main deal
      if (!validateDeal(deal, false)) {
        return;
      }

      // Check splits if they exist
      if (deal.splits) {
        for (const split of deal.splits) {
          if (!validateDeal(split, true)) {
            return;
          }
        }
      }
    }

    // If all validations pass, proceed with submission
    proceed();

    function validateDeal(deal, isSplit) {
      // Check verified quantity
      if (!deal._verifiedQty || Number(deal._verifiedQty) <= 0) {
        errorMessage = `Please enter a valid verified quantity for ${
          isSplit ? "split of " : ""
        }deal ${deal.dealName}`;
        errorRowId = deal._id;
        showErrorAndScroll();
        return false;
      }

      // Check if verified quantity exceeds requested quantity
      if (Number(deal._verifiedQty) > Number(deal.requestedQuantity)) {
        errorMessage = `Verified quantity cannot exceed requested quantity for ${
          isSplit ? "split of " : ""
        }deal ${deal.dealName}`;
        errorRowId = deal._id;
        showErrorAndScroll();
        return false;
      }

      // Check if reason is selected
      if (!deal.selectedReason) {
        errorMessage = `Please select a reason for ${
          isSplit ? "split of " : ""
        }deal ${deal.dealName}`;
        errorRowId = deal._id;
        showErrorAndScroll();
        return false;
      }

      // Check location details
      const locationFields = [
        { key: "selectedLocation", name: "Location" },
        { key: "selectedArea", name: "Area" },
        { key: "selectedRack", name: "Rack" },
        { key: "selectedBin", name: "Bin" },
      ];

      // Inside your validateDeal function
      const missingFields = locationFields.filter((field) => !deal[field.key]);

      if (missingFields.length > 0) {
        const missingFieldNames = missingFields
          .map((field) => field.name)
          .join(", ");
        errorMessage = `Please select ${missingFieldNames} for ${
          isSplit ? "split of " : ""
        }deal ${deal.dealName}`;
        errorRowId = deal._id;
        showErrorAndScroll();
        return false;
      }

      // Check MRP for split records
      if (isSplit && (!deal.editableMrp || Number(deal.editableMrp) <= 0)) {
        errorMessage = `Please enter a valid MRP for split of deal ${deal.dealName}`;
        errorRowId = deal._id;
        showErrorAndScroll();
        return false;
      }

      return true;
    }

    function showErrorAndScroll() {
      Toaster.error(errorMessage);
      if (errorRowId && rowRefs.current[errorRowId]) {
        rowRefs.current[errorRowId].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const proceed = async () => {
    console.log(details);
  };

  const doSplit = (dealId) => {
    const dealIndex = details.deals.findIndex((d) => d.dealId === dealId);
    if (dealIndex === -1) return;

    const deal = details.deals[dealIndex];

    // Check if entered quantity is valid
    if (
      !deal._verifiedQty ||
      isNaN(deal._verifiedQty) ||
      Number(deal._verifiedQty) < 0
    ) {
      Toaster.error(
        "Please enter a valid quantity (greater than or equal to 0) before splitting."
      );
      return;
    }

    const totalVerifiedQty = sum(
      [
        deal._verifiedQty,
        ...(deal.splits || []).map((s) => s._verifiedQty),
      ].map(Number)
    );

    if (totalVerifiedQty >= deal.requestedQuantity) {
      Toaster.error("Total verified quantity cannot exceed requested quantity");
      return;
    }

    setDetails(
      produce((draft) => {
        const draftDeal = draft.deals[dealIndex];
        const newSplit = {
          ...draftDeal,
          _id: Date.now().toString(), // Generate a unique ID for the split
          _verifiedQty: "",
          isSplit: true,
          selectedLocation: "",
          selectedArea: "",
          selectedRack: "",
          selectedBin: "",
        };

        if (!draftDeal.splits) {
          draftDeal.splits = [];
        }
        draftDeal.splits.push(newSplit);
      })
    );
  };

  const updateMrp = ({ dealId, splitId, mrp }) => {
    setDetails(
      produce((draft) => {
        const dealIndex = draft.deals.findIndex((d) => d.dealId === dealId);
        if (dealIndex === -1) return;
        const deal = draft.deals[dealIndex];
        if (splitId) {
          const splitIndex = deal.splits.findIndex((s) => s._id === splitId);
          if (splitIndex === -1) return;
          deal.splits[splitIndex].editableMrp = mrp;
        }
      })
    );
  };

  const cb = (e) => {
    if (e.action == "updateMrp") {
      updateMrp(e.data);
    }
    if (e.action == "split") {
      doSplit(e.data);
    }
    if (e.action == "removeSplit") {
      removeSplit(e.data.dealId, e.data.splitId);
    }
    if (e.action == "updateVerifiedQty") {
      updateVerifiedQty(e.data.dealId, e.data.splitId, e.data.verifiedQty);
    }
    if (e.action === "updateLocation") {
      updateLocation(e.data);
    }
    if (e.action === "updateReason") {
      updateReason(e.data);
    }
  };

  const updateVerifiedQty = (dealId, splitId, verifiedQty) => {
    setDetails(
      produce((draft) => {
        const dealIndex = draft.deals.findIndex((d) => d.dealId === dealId);
        if (dealIndex === -1) return;

        const deal = draft.deals[dealIndex];

        let targetDeal;
        if (splitId) {
          const splitIndex = deal.splits.findIndex((s) => s._id === splitId);
          if (splitIndex === -1) return;
          targetDeal = deal.splits[splitIndex];
        } else {
          targetDeal = deal;
        }

        targetDeal._verifiedQty = verifiedQty;

        // Calculate total verified quantity
        const totalVerifiedQty =
          Number(deal._verifiedQty || 0) +
          sum((deal.splits || []).map((s) => Number(s._verifiedQty) || 0));

        // Check if total verified quantity exceeds requested quantity
        if (totalVerifiedQty > deal.requestedQuantity) {
          targetDeal.qtyErrMsg = `Total verified quantity (${totalVerifiedQty}) cannot exceed requested quantity (${deal.requestedQuantity})`;
        } else {
          targetDeal.qtyErrMsg = null;
        }
      })
    );
  };

  const updateLocation = ({ dealId, splitId, locationType, locationValue }) => {
    setDetails(
      produce((draft) => {
        const dealIndex = draft.deals.findIndex((d) => d.dealId === dealId);
        if (dealIndex === -1) return;

        const deal = draft.deals[dealIndex];
        let targetDeal = deal;

        if (splitId) {
          const splitIndex = deal.splits.findIndex((s) => s._id === splitId);
          if (splitIndex === -1) return;
          targetDeal = deal.splits[splitIndex];
        }

        targetDeal[
          `selected${
            locationType.charAt(0).toUpperCase() + locationType.slice(1)
          }`
        ] = locationValue;
      })
    );
  };

  const removeSplit = async (dealId, splitId) => {
    const r = await Alert.confirm({
      title: "Remove Split",
      message: "Are you sure you want to remove this split?",
    });

    if (r.isConfirmed) {
      setDetails(
        produce((draft) => {
          const dealIndex = draft.deals.findIndex((d) => d.dealId === dealId);
          if (dealIndex === -1) return;
          const deal = draft.deals[dealIndex];
          deal.splits = deal.splits.filter((s) => s._id !== splitId);
        })
      );
    }
  };

  const updateReason = ({ dealId, splitId, reason }) => {
    setDetails(
      produce((draft) => {
        const dealIndex = draft.deals.findIndex((d) => d.dealId === dealId);
        if (dealIndex === -1) return;

        const deal = draft.deals[dealIndex];
        let targetDeal = deal;

        if (splitId) {
          const splitIndex = deal.splits.findIndex((s) => s._id === splitId);
          if (splitIndex === -1) return;
          targetDeal = deal.splits[splitIndex];
        }

        targetDeal.selectedReason = reason;
      })
    );
  };

  return (
    <>
      <div className="pt-2"></div>

      {loading ? <PageLoader /> : null}

      {!loading && !details._id ? (
        <NoDataFound>No data to display</NoDataFound>
      ) : null}

      {!loading && details?._id ? (
        <>
          <PageInfo
            title={"Store Returns - Inward Stock #" + query?.id}
            breadcrumbs={breadcrumbs}
            navigate={router}
          />

          <InfoBlk variant="primary">
            <div className="fs-val-md fw-semibold">
              <span className="bi bi-info-circle"></span>
              <span> Please scan the barcode to enter received qty</span>
            </div>
          </InfoBlk>

          <div className="mb-4">
            <StoreReturnBasicInfo data={details.basicInfo} />
          </div>

          <div>
            <AppCard title="List of Deals">
              <table className="table table-sm">
                <TableHeader data={theaders} />
                <tbody className="fs-val-md">
                  {details.deals.map((deal, k) => (
                    <React.Fragment key={`deal-group-${deal.dealId}`}>
                      <StoreReturnVerifyInwardRow
                        key={`deal-${deal.dealId}`}
                        data={deal}
                        callback={cb}
                        index={k}
                        ref={(el) => (rowRefs.current[deal.dealId] = el)}
                      />
                      {deal.splits &&
                        deal.splits.map((split, index) => (
                          <StoreReturnVerifyInwardRow
                            key={`split-${split._id}`}
                            data={split}
                            isSplit={true}
                            callback={cb}
                            index={k + "." + index}
                            ref={(el) => (rowRefs.current[split._id] = el)}
                          />
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </AppCard>
          </div>

          <AppCard>
            <div className="text-end">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </AppCard>

          <BusyLoader show={busyloader.show} />
        </>
      ) : null}
    </>
  );
}

export default StoreReturnVerifyInward;

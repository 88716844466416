// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a9cjZJ97P2p1BZRNxfC\\+Aw\\=\\={font-size:.8vw;width:150px;height:auto;border:none;border-bottom:1px solid #333;background-color:rgba(0,0,0,0);outline:none}.kQP73FSJBXzXvlCUChj1Tg\\=\\={position:absolute;top:5px}", "",{"version":3,"sources":["webpack://./src/pages/store-returns/StoreReturn.module.scss"],"names":[],"mappings":"AAAA,6BAGE,cAAA,CACA,WAAA,CAEA,WAAA,CAIA,WAAA,CACA,4BAAA,CAIA,8BAAA,CAEA,YAAA,CAGF,4BACE,iBAAA,CACA,OAAA","sourcesContent":[".loc-select {\n\n  /* Size adjustments */\n  font-size: .8vw;\n  width: 150px;\n  /* Adjust width if necessary */\n  height: auto;\n  /* Ensures the height adapts to content */\n\n  /* Border styling */\n  border: none;\n  border-bottom: 1px solid #333;\n  /* Bottom border only */\n\n\n  background-color: transparent;\n\n  outline: none;\n}\n\n.scanned-badge {\n  position: absolute;\n  top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loc-select": "a9cjZJ97P2p1BZRNxfC+Aw==",
	"scanned-badge": "kQP73FSJBXzXvlCUChj1Tg=="
};
export default ___CSS_LOADER_EXPORT___;

import { TextareaInput } from "@sk/uis";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";

const yupValidation = yup.object({
  remarks: yup.string().required().trim().label("Remarks"),
});

const defaultFormData = {
  remarks: "",
};

function RejectReasonModal({ show, callback, type, deal = {} }) {
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yupValidation),
    defaultValues: { ...defaultFormData },
  });

  useEffect(() => {
    if (show) {
      reset({ ...defaultFormData });
    }
  }, [reset, show]);

  const submit = (data) => {
    callback({ action: "submit", remarks: data.remarks, deal, type });
  };

  const onClose = () => {
    callback({ action: "close" });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton={true}>
        <Modal.Title className="mb-0">
          Reject {type == "deal" ? "Deal" : "Return Request"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type == "deal" ? (
          <div className="mb-4">
            <div className="text-muted fs-val-md">Deal</div>
            <div className="fs-val-normal fw-semibold">
              {deal?.name} -{" "}
              <span className="fs-val-sm text-muted">({deal._id})</span>
            </div>
          </div>
        ) : null}

        <TextareaInput
          register={register}
          name="remarks"
          label="Enter Remarks"
          isMandatory={true}
          error={errors.remarks?.message}
          maxLength={300}
          note="Max 300 characters"
        />
        <div className="text-end">
          <button className="btn btn-primary" onClick={handleSubmit(submit)}>
            Submit
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RejectReasonModal;
